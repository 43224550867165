export const APP_LIST_REQUEST = "APP_LIST_REQUEST";
export const APP_LIST_SUCCESS = "APP_LIST_SUCCESS";
export const APP_LIST_FAIL = "APP_LIST_FAIL";

export const APP_EDIT_REQUEST = "APP_EDIT_REQUEST";
export const APP_EDIT_SUCCESS = "APP_EDIT_SUCCESS";
export const APP_EDIT_FAIL = "APP_EDIT_FAIL";

export const APP_DELETE_REQUEST = "APP_DELETE_REQUEST";
export const APP_DELETE_SUCCESS = "APP_DELETE_SUCCESS";
export const APP_DELETE_FAIL = "APP_DELETE_FAIL";

export const APP_CREATE_REQUEST = "APP_CREATE_REQUEST";
export const APP_CREATE_SUCCESS = "APP_CREATE_SUCCESS";
export const APP_CREATE_FAIL = "APP_CREATE_FAIL";
export const APP_CREATE_RESET = "APP_CREATE_RESET";

export const APP_UPDATE_REQUEST = "APP_UPDATE_REQUEST";
export const APP_UPDATE_SUCCESS = "APP_UPDATE_SUCCESS";
export const APP_UPDATE_FAIL = "APP_UPDATE_FAIL";
export const APP_UPDATE_RESET = "APP_UPDATE_RESET";
