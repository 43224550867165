import { Link } from "react-router-dom";
import {useState } from 'react';
import { useDispatch } from "react-redux";
import { ForgotPassword } from "../../redux/actions/userActions";
import { useHistory } from 'react-router-dom';
import './main.css'; 

const ForgotPasswordPage = () =>{
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);  
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formState,setFormState] = useState({
    values:{}       
  });

  const handleChange = (event) => {
    setFormState(formState =>({
      ...formState,
      values:{
        ...formState.values,
        [event.target.name]:
        event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
      }  
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true); 
    setLoading(true);
    const { email } = formState.values;
    if (email) {
      dispatch(ForgotPassword(email)).then((response)=>{
        if(response.success === 1){
          setLoading(false);
          navigateLogin();
        } else {
          setLoading(false)
        }
      }); 
    }
  }

  const navigateLogin = () => {
		history.push('/login');
	};
    
	return(
        <>
          <div className="container-scroller loginInner">
            <div className="container page-body-wrapper full-page-wrapper">
              <div className="content-wrapper d-flex align-items-center auth px-0 loginInner">
                <div className="row w-100 mx-0 loginBg">
                  <div className="col-lg-5 ml-auto">
                    <div className="auth-form-light text-left py-4 px-4 px-sm-5 loginBox">
                      <div className="brand-logo">
                        <img src="assets/images/bqt_logo.png" alt="logo" />
                      </div>
                      <h4 className="text-center mt-5">Forgot Password?</h4>
                      <h6 className="fw-normal text-center mt-3">Enter an email-Id, we will sent an email to recover password. </h6>
                      <form className="pt-3" onSubmit={handleSubmit} autoComplete="off">
                        <div className="form-group">
                          <input type="email" className={'form-control form-control-lg' + (submitted && !formState.values.email ? ' is-invalid' : '')} 
                                        name="email" 
                                        placeholder="Email"
                                        onChange={handleChange}
                                        value={formState.values.email || ''}
                                        />
                                        {submitted && !formState.values.email &&
                                            <div className="inline-errormsg">Email is required</div>
                                        }
                        </div>
                        <div className="mt-3">
                          <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn loginBtn" >SEND RSET LINK</button>
                        </div>
                        <div className="my-2 d-flex justify-content-between align-items-center">
                          
                        </div>                    
                        <div className="text-center mt-4 fw-normal">
                           <Link to="/login" className="createLink">Go Back to Login</Link>
                        </div>
                      </form>
                      {loading && <div className="fullscreen-loader">Loading...</div>}
                    </div>
                  </div>
                </div>
              </div>          
            </div>        
          </div>
      </>
      )
}

export default ForgotPasswordPage;