export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const VERIFY_CODE_FAIL = "VERIFY_CODE_FAIL";
export const VERIFY_CODE_SUCCESS = "VERIFY_CODE_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_SUCCESS_RESET = "USER_REGISTER_SUCCESS_RESET";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const VERIFY_CODE_REQUEST = "VERIFY_CODE_REQUEST";

export const RESEND_CODE_FAIL = "RESEND_CODE_FAIL";
export const RESEND_CODE_SUCCESS = "RESEND_CODE_SUCCESS";
export const RESEND_CODE_REQUEST = "RESEND_CODE_REQUEST";

export const SEND_CODE_FAIL = "SEND_CODE_FAIL";
export const SEND_CODE_SUCCESS= "SEND_CODE_SUCCESS";
export const SEND_CODE_REQUEST = "SEND_CODE_REQUEST";

export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";
export const USER_ROLE = "USER_ROLE";
export const USER_DROPDOWN = "USER_DROPDOWN";

export const USER_EDIT_REQUEST = "USER_EDIT_REQUEST";
export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS";
export const USER_EDIT_FAIL = "USER_EDIT_FAIL";
export const USER_EDIT_RESET = "USER_EDIT_RESET";

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAIL = "USER_CREATE_FAIL";
export const USER_CREATE_RESET = "USER_CREATE_RESET";

