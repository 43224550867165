import {
    BUILD_CREATE_FAIL,
    BUILD_CREATE_REQUEST,
    BUILD_CREATE_SUCCESS,
    BUILD_DELETE_FAIL,
    BUILD_DELETE_REQUEST,
    BUILD_DELETE_SUCCESS,
    BUILD_EDIT_FAIL,
    BUILD_EDIT_REQUEST,
    BUILD_EDIT_SUCCESS,
    BUILD_LIST_FAIL,
    BUILD_LIST_REQUEST,
    BUILD_LIST_SUCCESS,
    BUILD_UPDATE_FAIL,
    BUILD_UPDATE_REQUEST,
    BUILD_UPDATE_SUCCESS,
    BUILD_EDIT_RESET
  } from "../constants/BuildConstants";
  import axios from "axios";
  import { logout } from "./userActions";
  import { toast } from "react-toastify";
  import {ToastObjects} from "./toastObject";
  
  export const listBuilds = (id, pageNum, buildsPerPage, sortBy, searchText, filter, filterValue, organizationId) => async (dispatch, getState) => {
    try {
      //if(organizationId !== undefined && organizationId !== null){
        dispatch({ type: BUILD_LIST_REQUEST });
  
        const responseData = await axios.get(`users/app/${id}/builds?page=${pageNum}&limit=${buildsPerPage}&sortBy=${sortBy}&searchText=${searchText}&filter=${filter}&filterValue=${filterValue}&organizationId=${organizationId}`);
        const data = responseData.data;
  
        data['sortBy'] = sortBy;
        data['searchText'] = searchText;
        data['numOfPages'] = Math.ceil(data.totalCount / buildsPerPage);
        data['app'] = data.app;
    
        dispatch({ type: BUILD_LIST_SUCCESS, payload: data });
    //  }
     
    } catch (error) {
      const message = error.message;
      if (message === "Authentication failed: Invalid token." || message ==="Unauthorized User" || message === "Request failed with status code 401") {
        dispatch(logout());
      }
      if(message === "Request failed with status code 401"){
        toast.error("Token Expired");
}else{
  toast.error(message);
}
      dispatch({
        type: BUILD_LIST_FAIL,
        payload: message,
      });
    }
  };
  
  // Delete Build
  export const deleteBuild = (id) => async (dispatch, getState) => {
  
    try {
      dispatch({ type: BUILD_DELETE_REQUEST });
  
      const response = await axios.delete(`users/build/${id}`);
  
      const responseData = response.data;
  
      if (!responseData.success) {
          toast.error(responseData.message, ToastObjects);  
        }else{
          toast.success(responseData.message, ToastObjects);  
          dispatch({ type: BUILD_DELETE_SUCCESS });
      }
    } catch (error) {
       const message =error.message;
      if (message === "Authentication failed: Invalid token." || message ==="Unauthorized User" ||message === "Request failed with status code 401") {
        dispatch(logout());
      }
      if(message === "Request failed with status code 401"){
        toast.error("Token Expired");
      }else{
        toast.error(message, ToastObjects);
      }
      dispatch({
        type: BUILD_DELETE_FAIL,
        payload: message,
      });
    }
  };
  
  // add Build
  export const addBuild = (appId,reqData) => async (dispatch, getState) => {
      
      try {
        dispatch({ type: BUILD_CREATE_REQUEST });
  
        const response = await axios.post(
          `users/app/${appId}/build`,
          reqData
        );
  
        const responseData = response.data;
  
        if (responseData.success === 0) {
          toast.error(responseData.message, ToastObjects);  
          return "error";
        }else{
          toast.success(responseData.message, ToastObjects);        
          dispatch({ type: BUILD_CREATE_SUCCESS, payload: responseData.data });  
          return "success";
        }
        
        
      } catch (error) {
        const message = error.message;
      if (message === "Authentication failed: Invalid token." || message ==="Unauthorized User" || message === "Request failed with status code 401") {
        dispatch(logout());
      }
        if(message === "Request failed with status code 401"){
          toast.error("Token Expired");
        }else{
          toast.error(message, ToastObjects);
        }
        dispatch({
          type: BUILD_CREATE_FAIL,
          payload: message,
        });
      }
    };
  
  // Edit Build
  export const editBuild = (app_id, id) => async (dispatch) => {
    try {
      dispatch({ type: BUILD_EDIT_REQUEST });
      const { data } = await axios.get(`users/app/${app_id}/build/${id}`);
      dispatch({ type: BUILD_EDIT_SUCCESS, payload: data });
      return data;
    } catch (error) {
      const message = error.message;
    if (message === "Authentication failed: Invalid token." || message ==="Unauthorized User" || message === "Request failed with status code 401") {
      dispatch(logout());
    } 
    if(message === "Request failed with status code 401"){
      toast.error("Token Expired");
    }else if(message === "Build not found."){
        toast.error(message, ToastObjects);
        return message;
      } else {
        dispatch({
          type: BUILD_EDIT_FAIL,
          payload: message,
        });
      }
    }
  };

  // GET LATEST Build
  export const getLatestBuild = (app_id, os, envirionment) => async (dispatch) => {
    try {
	    const { data } = await axios.get(`users/app/${app_id}/latest/build?os=${os}&envirionment=${envirionment}`);
	    return data;
    } catch (error) {
	    const message = error.message;
      if (message === "Authentication failed: Invalid token." ||message === "Unauthorized User" ||message === "Request failed with status code 401") {
	      dispatch(logout());
      } 
      if(message === "Request failed with status code 401"){
        toast.error("Token Expired");
      }else if(message === "No build found."){
	      toast.error(message, ToastObjects);
	      return message;
	    } else {
	      dispatch({
		      type: BUILD_EDIT_FAIL,
		      payload: message,
	      });
	    }
    }
  };
  
  // Update Build
  export const updateBuild = (reqData) => async (dispatch, getState) => {
  
    try {
      dispatch({ type: BUILD_UPDATE_REQUEST });
      const { data } = await axios.put(
        `users/build`,
        reqData      
      );
  
      dispatch({ type: BUILD_UPDATE_SUCCESS, payload: data });
      dispatch({ type: BUILD_EDIT_SUCCESS, payload: data });
    } catch (error) {
      const message = error.message;
    if (message === "Authentication failed: Invalid token." || message ==="Unauthorized User" || message === "Request failed with status code 401") {
      dispatch(logout());
    }
          if(message === "Request failed with status code 401"){
        toast.error("Token Expired");
      }else{
        toast.error(message)
      }
      dispatch({
        type: BUILD_UPDATE_FAIL,
        payload: message,
      });
    }
  };

  export const updateBuildAccess = (reqData, appId, buildId) => async (dispatch) => {
    try { 
      const { data } = await axios.post(
        `/users/app/${appId}/build/${buildId}`,
        reqData
      );
      dispatch({ type: BUILD_EDIT_RESET });
      if (!data.success) {
        toast.error(data.message, ToastObjects);
        return data;
      } else {
        toast.success(data.message, ToastObjects);
        return data;
      }
      
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      
      toast.error(message, ToastObjects);
    }
  };
  