import React, { useState } from 'react';
import './userDetails.css'; // Add any custom styling here

const UserDetails = () => {
  const [selectedTab, setSelectedTab] = useState('userDetails');
  const user = {
    name: 'John Doe',
    email: 'john.doe@example.com',
    role: 'Admin'
  };
  const apps = [
    { id: 1, name: 'App 1', role: 'Member' },
    { id: 2, name: 'App 2', role: 'Owner' },
    { id: 3, name: 'App 3', role: 'Member' }
  ];
  const onTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div className="app">
       <nav className="navbar">
      <div className="nav-item" onClick={() => onTabChange('userDetails')}>
        User Details
      </div>
      <div className="nav-item" onClick={() => onTabChange('apps')}>
        Apps
      </div>
    </nav>
    {selectedTab === 'userDetails' && 
     <div className="user-details">
     <h2>User Details</h2>
     <p><strong>Name:</strong> {user.name}</p>
     <p><strong>Email:</strong> {user.email}</p>
     <p><strong>Role:</strong> {user.role}</p>
   </div>
   }
    {selectedTab === 'apps' && 
     <div className="apps">
     <h2>Apps</h2>
     <ul>
       {apps.map(app => (
         <li key={app.id}>
           <strong>{app.name}</strong> - {app.role}
         </li>
       ))}
     </ul>
   </div>}
    </div>
  );
};

export default UserDetails;