import {
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  RESEND_CODE_FAIL,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_REQUEST,
  SEND_CODE_FAIL,
  SEND_CODE_SUCCESS,
  SEND_CODE_REQUEST,
  USER_REGISTER_FAIL,
  VERIFY_CODE_FAIL,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_REQUEST,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_SUCCESS_RESET,
  USER_LOGOUT,
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_RESET,
  USER_CREATE_SUCCESS,
  USER_DROPDOWN,
  USER_EDIT_FAIL,
  USER_EDIT_REQUEST,
  USER_EDIT_RESET,
  USER_EDIT_SUCCESS,
} from "../constants/UserContants";

// Register
export const userRegisterReducer = (state = { }, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, register_status: true };
    case USER_REGISTER_SUCCESS_RESET:
      return { loading: false, register_status: false };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// VerifyCode
export const userVerifyReducer = (state = { role: ''}, action) => {
  switch (action.type) {
    case VERIFY_CODE_REQUEST:
      return { loading: true };
    case VERIFY_CODE_SUCCESS:
      return { loading: false, userInfo: action.payload, role: action.payload.data.role };
    case VERIFY_CODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// ResenCode
export const userResendReducer = (state = { role: ''}, action) => {
  switch (action.type) {
    case RESEND_CODE_REQUEST:
      return { loading: true };
    case RESEND_CODE_SUCCESS:
      return { loading: false, userInfo: action.payload, role: action.payload.data.role };
    case RESEND_CODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
// ResenCode
export const usersendCodeReducer = (state = { role: ''}, action) => {
  switch (action.type) {
    case SEND_CODE_REQUEST:
      return { loading: true };
    case SEND_CODE_SUCCESS:
      return { loading: false, userInfo: action.payload, role: action.payload.data.role };
    case SEND_CODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
// Login
export const userLoginReducer = (state = { role: ''}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload, role: action.payload.data.role };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

// Get Users List
export const userListReducer = (state = { users: [], numOfPages:0 }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true, users: [] };
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload.data, numOfPages: action.payload.numOfPages, };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_LIST_RESET:
      return { users: [] };
    default:
      return state;
  }
};

//create user
export const userCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return { loading: true };
    case USER_CREATE_SUCCESS:
      return { loading: false, success: true, user: action.payload };
    case USER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// Get Users List
export const userDropdown = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_DROPDOWN:
      return { loading: false, users: action.payload.data, };
    default:
      return state;
  }
};

// Get Use
export const userEditReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_EDIT_REQUEST:
      return { loading: true, users: [] };
    case USER_EDIT_SUCCESS:
      return { loading: false, user: action.payload.data };
    case USER_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case USER_EDIT_RESET:
      return { user: {} };
    default:
      return state;
  }
};
