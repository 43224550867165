import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { 
  userListReducer,
  userLoginReducer,
  userRegisterReducer,
  userCreateReducer,
  userDropdown,
  userEditReducer
} from "./reducers/userReducers";
import {
  productCreateReducer,
  productDeleteReducer,
  productEditReducer,
  productListReducer,
  productUpdateReducer,
} from "./reducers/ProductReducers";
import {
  orderDeliveredReducer,
  orderDetailsReducer,
  orderListReducer,
} from "./reducers/OrderReducres";
import {
  appCreateReducer,
  appDeleteReducer,
  appEditReducer,
  appListReducer,
  appUpdateReducer,
} from "./reducers/AppReducers";
import {
  buildCreateReducer,
  buildDeleteReducer,
  buildEditReducer,
  buildListReducer,
  buildUpdateReducer,
} from "./reducers/BuildReducers";
import {
  organizationCreateReducer,
  organizationDeleteReducer,
  organizationEditReducer,
  organizationListReducer,
  organizationDetailsReducer,
  organizationUpdateReducer,
} from "./reducers/OrganizationReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userList: userListReducer,
  userCreate: userCreateReducer,
  userEdit: userEditReducer,
  userDropdown: userDropdown,
  productList: productListReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productEdit: productEditReducer,
  productUpdate: productUpdateReducer,
  orderList: orderListReducer,
  orderDetails: orderDetailsReducer,
  orderDeliver: orderDeliveredReducer,
  appList: appListReducer,
  appDelete: appDeleteReducer,
  appCreate: appCreateReducer,
  appEdit: appEditReducer,
  appUpdate: appUpdateReducer,
  buildList: buildListReducer,
  buildDelete: buildDeleteReducer,
  buildCreate: buildCreateReducer,
  buildEdit: buildEditReducer,
  buildUpdate: buildUpdateReducer,
  organizationList: organizationListReducer,
  organizationDetails:organizationDetailsReducer,
  organizationDelete: organizationDeleteReducer,
  organizationCreate: organizationCreateReducer,
  organizationEdit: organizationEditReducer,
  organizationUpdate: organizationUpdateReducer,
});

// login
const userInfoFromLocalStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromLocalStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
