import {
    BUILD_CREATE_FAIL,
    BUILD_CREATE_REQUEST,
    BUILD_CREATE_RESET,
    BUILD_CREATE_SUCCESS,
    BUILD_DELETE_FAIL,
    BUILD_DELETE_REQUEST,
    BUILD_DELETE_SUCCESS,
    BUILD_EDIT_FAIL,
    BUILD_EDIT_REQUEST,
    BUILD_EDIT_SUCCESS,
    BUILD_LIST_FAIL,
    BUILD_LIST_REQUEST,
    BUILD_LIST_SUCCESS,
    BUILD_UPDATE_FAIL,
    BUILD_UPDATE_REQUEST,
    BUILD_UPDATE_RESET,
    BUILD_EDIT_RESET,
    BUILD_UPDATE_SUCCESS,
  } from "../constants/BuildConstants";
  
  // All builds
  export const buildListReducer = (state = { builds: [], numOfPages:0, sortBy: '', searchText:'', app: {} }, action) => {
    switch (action.type) {
      case BUILD_LIST_REQUEST:
        return { loading: true, builds: [] };
      case BUILD_LIST_SUCCESS:
        return { loading: false, builds: action.payload.data, numOfPages: action.payload.numOfPages, sortBy: action.payload.sortBy, searchText: action.payload.searchText, app:action.payload.app };
      case BUILD_LIST_FAIL:
        return { loading: false, error: action.payload.data, numOfPages: action.payload.numOfPages, sortBy: action.payload.sortBy, searchText: action.payload.searchText, app:action.payload.app };
      default:
        return state;
    }
  };
  
  // Delete build
  export const buildDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case BUILD_DELETE_REQUEST:
        return { loading: true };
      case BUILD_DELETE_SUCCESS:
        return { loading: false, success: true };
      case BUILD_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // Create build
  export const buildCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case BUILD_CREATE_REQUEST:
        return { loading: true };
      case BUILD_CREATE_SUCCESS:
        return { loading: false, success: true, build: action.payload };
      case BUILD_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case BUILD_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  // Edit build
  export const buildEditReducer = (
    state = { build: { } },
    action
  ) => {
    switch (action.type) {
      case BUILD_EDIT_REQUEST:
        return { ...state, loading: true };
      case BUILD_EDIT_SUCCESS:
        return { loading: false, build: action.payload.data };
      case BUILD_EDIT_FAIL:
        return { loading: false, error: action.payload.data };
      case BUILD_EDIT_RESET:
        return { build: {} };
      default:
        return state;
    }
  };
  
  // Update build
  export const buildUpdateReducer = (state = { build: {} }, action) => {
    switch (action.type) {
      case BUILD_UPDATE_REQUEST:
        return { loading: true };
      case BUILD_UPDATE_SUCCESS:
        return { loading: false, success: true, build: action.payload };
      case BUILD_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case BUILD_UPDATE_RESET:
        return { build: {} };
      default:
        return state;
    }
  };
  