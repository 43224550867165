import { Link } from "react-router-dom";
import {useState } from 'react';
import { useDispatch } from "react-redux";
import { ResendCode, Verify } from "../../redux/actions/userActions";
import { useHistory } from 'react-router-dom';
import './login.css';
import { Circles } from 'react-loader-spinner'; 

const VerifyPage = ({match}) =>{
  const history = useHistory();
  const token = match.params.token;
  const [submitted, setSubmitted] = useState(false);  
  const dispatch = useDispatch();
  const [showResend, setShowResend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formState,setFormState] = useState({
        values:{}       
  });

  const handleChange = (event) => {
        setFormState(formState =>({
          ...formState,
          values:{
            ...formState.values,
            [event.target.name]:
            event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
          }
          
        }));
      }

  const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true); 
        const { code } = formState.values;
        if (code && token) {
          setLoading(true);
          dispatch(Verify(code,token)).then((response)=>{
            if(response?.message === 'Invalid Code'){
              setShowResend(true);
              setLoading(false);
            } else if(response?.message === 'User Verified successfully.' || response?.message === 'User Already Verified.'){
              setLoading(false);
              history.push('/login');
            }
          });
        }
    }

   const handleResendCode =()=>{
    if (token) {
      setLoading(true);
      dispatch(ResendCode(token)).then((response)=>{
        setLoading(false)
      })
    }
   }

   return (
    <>          
      <div className="container-scroller loginInner">
        <div className="container page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0 loginInner">
            <div className="row w-100 mx-0 loginBg">
              <div className="col-lg-5 ml-auto">
                <div className="auth-form-light text-left py-4 px-4 px-sm-5 loginBox">
                  <div className="brand-logo">
                    <img src="src/images/bqt_logo.png" alt="logo" />
                  </div>
                  <h4 className="text-center mt-5">Hello!</h4>
                  <h6 className="fw-normal text-center mt-3">Enter the verification code sent to your registered Email-Id.</h6>
                  <form className="pt-3" onSubmit={handleSubmit} autoComplete="off">
                    <div className="form-group">
                      <input
                        type="code"
                        className={'form-control form-control-lg' + (submitted && !formState.values.code ? ' is-invalid' : '')}
                        name="code"
                        placeholder="Enter Code"
                        onChange={handleChange}
                        value={formState.values.code || ''}
                      />
                      {submitted && !formState.values.code && (
                        <div className="inline-errormsg">Code is required</div>
                      )}
                    </div>
                    <div className="mt-3">
                      <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn loginBtn">Verify</button>
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      <div className="form-check">
                        {/* Additional form check can be added here if needed */}
                      </div>

                      {showResend === true ?
                        <Link to="#" onClick={handleResendCode}  className="createLink fw-normal">Resend Code</Link>
                      : null}
                    </div>                    

                  </form>
                </div>
              </div>
            </div>
          </div>          
        </div>        
      </div>
      {loading &&  
        <div className="fullscreen-loader">
          <Circles color="#1F3BB3" height={80} width={80} />
        </div>
      }
    </>
  );
}

export default VerifyPage;