import {Link} from 'react-router-dom';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import { deleteBuild } from "../../../redux/actions/BuildActions";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";

const Build = (props) => {
	let { id, version, os, created_at, file_type, file_path, signedUrl, envirionment
	} = props.build;//role, 
	let appId = props.app.id;
	let _organizationId = props?.organizationId;
	const organization = useSelector((state)=>state.organizationDetails.organization)
    const organizationRoles = organization?.user_role;
	const organizationId = organization?.organization_id;
	const role = useSelector((state) => state.userLogin.userInfo.data.role);
	const dispatch = useDispatch();

	  const deletehandler = (id) => {
	    if (window.confirm("Are you sure want to delete build?")) {
	      dispatch(deleteBuild(id));
	    }
	  };

      const downloadBuild = () => {
        const link = document.createElement('a');
        link.href = file_path;
        link.download = `app-${version}`; 
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
	  };

	  const copyUrl = (e) => {
		e.preventDefault();
		navigator.clipboard.writeText(`itms-services://?action=download-manifest&url=${signedUrl}`).then(() => {
		  alert("Installation URL copied");
		}).catch(err => {
		  console.error("Failed to copy text: ", err);
		});
	  };

	return(
		<>
		    <tr>
              {/* <td>{sort}</td> */}
              <td>{version}</td>
              <td>{ moment(created_at).format('MMM DD, YYYY, hh:mm A')}</td>
			  <td>{os}</td>
              <td>{file_type}</td>
			  <td>{envirionment}</td>
			  <td className="build-download">
				<Link
	                to="#"
                    onClick={() => downloadBuild()}  
					style={{ "paddingRight": "10px" }}    
					title="Download Build"           
	            >
	              	<i className="fa fa-download"></i>
	            </Link>
                {os === 'iOS'?
				 <>
				   	<a
	                   href={`itms-services://?action=download-manifest&url=${signedUrl}`}  
					   style={{ "paddingRight": "10px" }}  
					   title="Install Build"            
	                >
	                 	<i className="fa fa-apple"></i>
	                </a>

				    <Link
	                  to="#"  
					  onClick={copyUrl}
					  style={{ paddingRight: "10px", cursor: "pointer" }}
					  title="Copy Installable URL"         
	                >
	                 	<i className="fa fa-clipboard"></i>
	                </Link>
				 </>
				: null}

                { role === 'Super_Admin' ? 
				    <Link
				      to={`/organization/${_organizationId}/app/${appId}/build/${id}/view`}	 
				      style={{ "paddingRight": "10px" }}  
				      title="View Build Details"                 
			        >
				      <i className="fa fa-eye"></i>
			        </Link>
			    : 
				    <Link
				       to={`/app/${appId}/build/${id}/view`}	 
				       style={{ "paddingRight": "10px" }}  
				       title="View Build Details"                 
			        >
				      <i className="fa fa-eye"></i>
			        </Link>
				}
	           </td>
            </tr>
		</>
		)
}

export default Build;