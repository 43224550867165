import { useState, useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import { addBuild, getLatestBuild } from '../../../redux/actions/BuildActions'; 
import { UserDropdown } from '../../../redux/actions/userActions';
import { useHistory } from 'react-router-dom';
import { editApp } from '../../../redux/actions/AppActions'; 
import { Circles } from 'react-loader-spinner'; 
import { FaApple, FaAndroid } from 'react-icons/fa';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../main.css';

const AddBuild = ({match}) => {
    const appId = match.params.id;
    const history = useHistory();
	const dispatch = useDispatch();
	const appEdit = useSelector((state) => state.appEdit);
	const users = useSelector((state) => state.userDropdown.users);
    const [submitted, setSubmitted] = useState(false);  
	const [osSelect, setOsSelect] = useState(false); 
    const [uploadedFile, setUploadedFile] = useState(null)
	const [fileType, setFileType ] = useState('.ipa');
	const [loading, setLoading] = useState(false); 
	const [selectedUserIds, setSelectedUserIds] = useState([]);
	const [selectedClientIds, setSelectedClientIds] = useState([]);
	const [formState,setFormState] = useState({
        values:{
			os: 'iOS',
			version:'',
			notes:''
		},
		errors: {}       
    });
	const [fileError, setFileError] = useState('');
	const [envirionment, setEnvirionment] = useState('Production');
	const organization = useSelector((state)=>state?.organizationDetails?.organization);
	const organizationId = organization?.organization_id;

	const { app } = appEdit;

	useEffect(() => {
		if (!app.name || app.id !== Number(appId)) {
		  dispatch(editApp(appId));        
		} else {
			if(app.isAndriod === 1 && app.isIos === 1){
              setOsSelect(true);
			  setBuildVersion(appId, 'iOS', envirionment)
			} else if(app.isAndriod === 1){
				setFormState({
					values: {
						os: 'Android',
						version: '',
						notes: ''
					},
					errors: {
						...formState.errors
					}    
				})
				setFileType('.apk')
				setBuildVersion(appId, 'Android', envirionment)
			} else if(app.isIos === 1){
				setFormState({
					values: {
						os: 'iOS',
						version: '',
						notes: ''
					},
					errors: {
						...formState.errors
					}    
				})
				setFileType('.ipa')
				setBuildVersion(appId, 'iOS', envirionment)
			}
		}
	}, [app, dispatch, appId]);

	useEffect(() => {
    	dispatch(UserDropdown('build', appId, organizationId));
  	}, [organizationId]);

	const handleUserChange = (event) => {
	  const selectedUserId = parseInt(event.target.value);
	  if (!selectedUserIds.includes(selectedUserId)) {
		setSelectedUserIds([...selectedUserIds, selectedUserId]);
	  }
	};

	const handleClientChange = (event) => {
		const selectedClientId = parseInt(event.target.value);
		if (!selectedClientIds.includes(selectedClientId)) {
		  setSelectedClientIds([...selectedClientIds, selectedClientId]);
		}
	  };
  
	const removeUser = (userIdToRemove) => {
	  setSelectedUserIds(selectedUserIds.filter(userId => userId !== userIdToRemove));
	};

	const removeClient = (clientIdToRemove) => {
		setSelectedClientIds(selectedClientIds.filter(clientId => clientId !== clientIdToRemove));
	};
  
	const filteredOptions = users.filter(option => !selectedUserIds.includes(option.id) && option.role !== 'Client');
	const selectedUsers = users.filter(option => selectedUserIds.includes(option.id) && option.role !== 'Client');
    const members = users.filter(option => option.role !== 'Client');

	const filteredClientOptions = users.filter(option => !selectedClientIds.includes(option.id) && option.role === 'Client');
    const selectedClients = users.filter(option => selectedClientIds.includes(option.id) && option.role === 'Client');
    const clients = users.filter(option => option.role === 'Client');
	
    const handleChange = (event) => {
        setFormState(formState =>({
          ...formState,
          values:{
            ...formState.values,
            [event.target.name]:
            event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
          },
		  errors: {
			...formState.errors,
			[event.target.name]: getValidationError(event.target.name, event.target.value)
		  }
        }));
    }

	const getValidationError = (name, value) => {
		switch (name) {
		  case 'version':
			return validateVersion(value);
		  default:
			return '';
		}
	};

	const validateVersion = (version) => {
		if(envirionment === 'Staging'){
			const versionPattern = /^(\d+)\.(\d+)\.(\d+)$/;
			const match = version.match(versionPattern);

			if (!match) {
				return 'Version must be in the format x.x.x (e.g. 1.0.0, 0.1.0)';
			}

			const [major, minor, patch] = match.slice(1).map(num => (num !== undefined ? Number(num) : 0));
			if (major < 0 || minor < 0 || patch < 1) {
				return 'For staging, version must be 0.0.1';
			}

		} else if(envirionment === 'Production'){
			const versionPattern = /^(\d+)\.(\d+)$/;
			const match = version.match(versionPattern);

			if (!match) {
				return 'Version must be in the format x.x  (e.g., 1.0, 1.1)';
			}

			const [major, minor, patch] = match.slice(1).map(num => (num !== undefined ? Number(num) : 0));
			if (major < 1 || (major === 1 && minor < 0) || patch >=0) {
				return 'For production, version must be 1.0 or higher';
			}
		}
	  };
	  

    const handleUploadChange = (event) => {
        event.preventDefault();
		const file = event.target.files[0];
		
		if (file && !file.name.endsWith(fileType)) {
			setFileError(`Invalid file type. Please upload a ${fileType} file.`);
			setUploadedFile(null);
		  } else {
			setFileError('');
			setUploadedFile(file);
		  }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true); 
        const { os, version, notes } = formState.values;
        let payload = new FormData();

        payload.set("version", version);
        payload.set("notes", notes);
		payload.set("os", os);
        payload.append("file", uploadedFile);
		payload.set("selectedUserIds", selectedUserIds);
		payload.set("selectedClientIds", selectedClientIds);
		payload.set("envirionment", envirionment)
console.log(formState)
      if (os && notes && version && uploadedFile) {
		if (!formState?.errors?.version && formState?.values?.version) {
			if(envirionment.length > 0){
				setLoading(true)
				dispatch(addBuild(appId, payload)).then((response)=>{
					if(response === 'success'){
						setFormState({
							values: {
								os: '',
								version: '',
								notes: ''
							},
							errors: {}
						})
						setSubmitted(false);
						setUploadedFile(null)
						navigateToBuildList();
					} else {
						setLoading(false)
					}
				});
			} else {
				alert("Please select envirionment")
			}
		} else {
           alert(formState.errors.version)
		}
       } else {
		if(fileError){
			alert(fileError)
		} else {
           alert("Fill all the details....")
		}
	   }
    }

	const navigateToBuildList = () => {
		history.push(`/app/${appId}/builds`);
	};

	const handleOs = (e) => {
		if(e.target.value === 'Android'){
			setFileType('.apk')
			setBuildVersion(appId, 'Android', envirionment)
		} else if(e.target.value === 'iOS'){
			setBuildVersion(appId, 'iOS', envirionment)
			setFileType('.ipa')
		} else {
			setFileType('')
		}

		setUploadedFile(null)
		setFileError('')
		setFormState(formState =>({
			...formState,
			values:{
			  ...formState.values,
			  'os' : e.target.value
			},
			errors: {
				...formState.errors
			}    
		}));
	}

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setFormState({
			...formState,
			values: {
				...formState.values,
				notes: data,
			},
			errors: {
				...formState.errors
			} 
		});
	};

	const handleEnvirionment = (e) => {
		setEnvirionment(e.target.value);
		setBuildVersion(appId, formState?.values?.os, e.target.value)
	}

	const setBuildVersion = (appId, os, envirionment) => {
		dispatch(getLatestBuild(appId, os, envirionment)).then((response)=>{
			if(response){
				const versionParts = response?.data.split('.').map(Number);
				if(envirionment === 'Staging'){
					versionParts[2] += 1;
					setFormState(formState =>({
						...formState,
						values:{
						  ...formState.values,
						  version: versionParts.join('.')
						},
						errors: {
							...formState.errors
						} 
					}));
				} else {
					versionParts[1] += 1;
					setFormState(formState =>({
						...formState,
						values:{
						  ...formState.values,
						  version: versionParts.join('.')
						},
						errors: {
							...formState.errors
						} 
					}));
				}
			} else {
				setFormState(formState =>({
					...formState,
					values:{
					  ...formState.values,
					  version: envirionment === 'Staging' ? '1.0.0' : '1.0'
					},
					errors: {
						...formState.errors
					} 
				}));
			}
		});
	}

	return(
		<>
		    <div className="container-scroller">
				<Header/>
				<div className="container-fluid page-body-wrapper">
				   <Sidebar/>
				   <div className="main-panel">
				        <div className="content-wrapper">
					        <div className="row">
				               <div className="col-12 grid-margin">
				                  <div className="card">
				                     <div className="card-body">
				                        <h4 className="card-title">
										<Link
	                             to={`/app/${app.id}/builds`}	                
	                           >
	              	            {app?.name}
	                           </Link> {'>'} New Build</h4>
				                        <form className="form-sample" onSubmit={handleSubmit}>
				                           <p className="card-description">				                              
				                           </p>
										    <div className="row">
										        <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">
                                                          OS <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <div className="col-sm-9 d-flex align-items-center" style={{ "paddingLeft":"30px"}}>
															{osSelect === true || formState.values.os ==='iOS' ?
                                                           <div className="form-check form-check-inline" style={{ "paddingLeft":"10px"}}>
                                                                <input 
                                                                    className="form-check-input" 
                                                                    type="radio" 
                                                                    name="os" 
                                                                    id="iosRadio" 
                                                                    value="iOS" 
                                                                    onChange={handleOs} 
																	checked={formState.values.os === 'iOS'}
                                                                />
                                                                <label className="form-check-label" htmlFor="iosRadio" style={{"marginLeft":"0rem"}}>
                                                                    <FaApple  style={{ marginRight: "8px" }}/>
                                                                    iOS
                                                                </label>
                                                            </div>
															:null}
															{osSelect === true || formState.values.os ==='Android' ?
                                                            <div className="form-check form-check-inline" style={{ paddingLeft: "30px" }}>
															<input 
																className="form-check-input" 
																type="radio" 
																name="os" 
																id="androidRadio" 
																value="Android" 
																onChange={handleOs} 
																checked={formState.values.os === 'Android'} 
															/>
															<label className="form-check-label" htmlFor="androidRadio" style={{ marginLeft: "0rem" }}>
																<FaAndroid style={{ marginRight: "8px" }} /> {/* Add space here */}
																Android
															</label>
														</div>
															:null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

											<div className="row">
				                              <div className="col-md-6">
				                                 <div className="form-group row">
				                                    <label className="col-sm-3 col-form-label">Environment
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="col-sm-9">
														<select className="form-select form-control" aria-label="Envirionment" value={envirionment} onChange={handleEnvirionment}>
															<option value="">Select Environment</option>
															<option value="Production">Production</option>
															<option value="Staging">Staging</option>
														</select>
				                                    </div>
				                                 </div>
				                              </div>				                              
				                           </div>
										   
				                           <div className="row">
				                              <div className="col-md-6">
				                                 <div className="form-group row">
				                                    <label className="col-sm-3 col-form-label">Build Version
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="col-sm-9">
									                        <input type="text" className={'form-control form-control-lg' + (submitted && !formState.values.version ? ' is-invalid' : '')} 
					                                        name="version"                                
					                                        onChange={handleChange}
					                                        value={formState.values.version || ''}
					                                        />
					                                        {submitted && formState.values.version.length === 0 &&
					                                            <div className="inline-errormsg">Version name is required</div>
					                                        }
															{submitted && formState.errors.version && (
                                                                <div className="invalid-feedback">{formState.errors.version}</div>
                                                             )}
				                                    </div>
				                                 </div>
				                              </div>				                              
				                           </div>
										   
				                           <div className="row">
				                              <div className="col-md-9">
				                                 <div className="form-group row">
				                                    <label className="col-sm-2 col-form-label">Notes
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="col-sm-10">
													<style>
                                                        {   `.ck-editor__editable_inline {
                                                              min-height: 200px;
                                                            }`
														}
                                                    </style>
				                                       {/* <textarea rows={5} cols={5} className={'form-control form-control-lg' + (submitted && !formState.values.notes ? ' is-invalid' : '')} 
					                                        name="notes"                          
					                                        onChange={handleChange}
					                                        value={formState.values.notes || ''}
					                                        /> */}
														<CKEditor
                                                            editor={ClassicEditor}
                                                            data={formState.values.notes || ''}
                                                            onChange={handleEditorChange}
															
                                                        />

					                                    {submitted && !formState.values.notes &&
					                                        <div className="inline-errormsg">Notes is required</div>
					                                    }
				                                    </div>
				                                 </div>
				                              </div>				                              
				                           </div>
										   { formState.values.os.length > 0 ?
										     <div className="row">
											   <div className="col-md-6">
												  <div className="form-group row">
													 <label className="col-sm-3 col-form-label">Upload Build
													 <span style={{ color: 'red' }}>*</span>
													 </label>
													 <div className="col-sm-9">
														<input type="file" className={'form-control form-control-lg' + (submitted && !uploadedFile ? ' is-invalid' : '')} 
															name="file" 
															single
															accept={fileType}
															onChange={handleUploadChange}
														/>
														{fileError && (
                                                            <div className="inline-errormsg">{fileError}</div>
                                                        )}

														{submitted && !uploadedFile && !fileError &&
															<div className="inline-errormsg">File is required</div>
														}
													 </div>
												  </div>
											   </div>				                              
											 </div>
                                            : null}

<div className="row">
                                                <div className="col-md-6">
	                                                <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Sent Email To:</label>
                                                        <div className="col-sm-9"  style={{"paddingLeft":"20px"}}>
															{members.length > 0 ? 
															    <div className="custom-select-container">
																<div className="selected-roles">
																	{selectedUsers.map((user) => (
																		<span key={user.id} className="selected-role">
																		   {user.name}
																		   <button type="button" className="remove-role-btn" onClick={() => removeUser(user.id)}>x</button>
																		</span>
																	))}
																	<select className="form-control role-select" onChange={handleUserChange} value="">
																		<option value="" disabled>Select User</option>
																		{filteredOptions.map((user) => (
																			<option key={user.id} value={user.id}>{user.name}</option>
																		))}
																	</select>
																</div>
																</div>
														    : 
														    	<label className="col-sm-12 col-form-label">Contact Admin, to add Member in {app?.name} to sent email.</label>
														    }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
	                                                <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label">Client Access:</label>
                                                        <div className="col-sm-9"  style={{"paddingLeft":"20px"}}>
															{clients.length > 0 ? 
															  <div className="custom-select-container">
															  <div className="selected-roles">
																  {selectedClients.map((user) => (
																	  <span key={user.id} className="selected-role">
																		 {user.name}
																		 <button type="button" className="remove-role-btn" onClick={() => removeClient(user.id)}>x</button>
																	  </span>
																  ))}
																  <select className="form-control role-select" onChange={handleClientChange} value="">
																	  <option value="" disabled>Select Client</option>
																	  {filteredClientOptions.map((user) => (
																		  <option key={user.id} value={user.id}>{user.name}</option>
																	  ))}
																  </select>
															  </div>
															  </div>
															:
															  <label className="col-sm-12 col-form-label">Contact Admin, to add Client in {app?.name}.</label>
															}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
				                            <div className="text-center">
				                            	<button type="submit" className="Button col-sm-2 col-form-label text-center mr-2">Submit</button>
                    							<Link to={`/app/${appId}/builds`}><button className="ButtonLight col-sm-2 col-form-label text-center ml-2">Cancel</button></Link>
                    						</div>
				                        </form>
				                     </div>
				                  </div>
				               </div>
				            </div>
			            </div>
				       <Footer/>
				    </div>
				</div>
			</div>
			{loading &&  <div className="fullscreen-loader">
          <Circles color="#1F3BB3" height={80} width={80} />
        </div>}
		
		</>
		
		)
}

export default AddBuild;