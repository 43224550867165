export const BUILD_LIST_REQUEST = "BUILD_LIST_REQUEST";
export const BUILD_LIST_SUCCESS = "BUILD_LIST_SUCCESS";
export const BUILD_LIST_FAIL = "BUILD_LIST_FAIL";

export const BUILD_EDIT_REQUEST = "BUILD_EDIT_REQUEST";
export const BUILD_EDIT_SUCCESS = "BUILD_EDIT_SUCCESS";
export const BUILD_EDIT_FAIL = "BUILD_EDIT_FAIL";
export const BUILD_EDIT_RESET = "BUILD_EDIT_RESET";

export const BUILD_DELETE_REQUEST = "BUILD_DELETE_REQUEST";
export const BUILD_DELETE_SUCCESS = "BUILD_DELETE_SUCCESS";
export const BUILD_DELETE_FAIL = "BUILD_DELETE_FAIL";

export const BUILD_CREATE_REQUEST = "BUILD_CREATE_REQUEST";
export const BUILD_CREATE_SUCCESS = "BUILD_CREATE_SUCCESS";
export const BUILD_CREATE_FAIL = "BUILD_CREATE_FAIL";
export const BUILD_CREATE_RESET = "BUILD_CREATE_RESET";

export const BUILD_UPDATE_REQUEST = "BUILD_UPDATE_REQUEST";
export const BUILD_UPDATE_SUCCESS = "BUILD_UPDATE_SUCCESS";
export const BUILD_UPDATE_FAIL = "BUILD_UPDATE_FAIL";
export const BUILD_UPDATE_RESET = "BUILD_UPDATE_RESET";
