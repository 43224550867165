export const ORGANIZATION_LIST_REQUEST = "ORGANIZATION_LIST_REQUEST";
export const ORGANIZATION_LIST_SUCCESS = "ORGANIZATION_LIST_SUCCESS";
export const ORGANIZATION_LIST_FAIL = "ORGANIZATION_LIST_FAIL";
export const ORGANIZATION_LIST_RESET = "ORGANIZATION_LIST_RESET";

export const ORGANIZATION_DETAILS_REQUEST = "ORGANIZATION_UPDATE_REQUEST";
export const ORGANIZATION_DETAILS_SUCCESS = "ORGANIZATION_UPDATE_SUCCESS";
export const ORGANIZATION_DETAILS_FAIL = "ORGANIZATION_UPDATE_FAIL";
export const ORGANIZATION_DETAILS_RESET = "ORGANIZATION_UPDATE_RESET";

export const ORGANIZATION_EDIT_REQUEST = "ORGANIZATION_EDIT_REQUEST";
export const ORGANIZATION_EDIT_SUCCESS = "ORGANIZATION_EDIT_SUCCESS";
export const ORGANIZATION_EDIT_FAIL = "ORGANIZATION_EDIT_FAIL";

export const ORGANIZATION_DELETE_REQUEST = "ORGANIZATION_DELETE_REQUEST";
export const ORGANIZATION_DELETE_SUCCESS = "ORGANIZATION_DELETE_SUCCESS";
export const ORGANIZATION_DELETE_FAIL = "ORGANIZATION_DELETE_FAIL";

export const ORGANIZATION_CREATE_REQUEST = "ORGANIZATION_CREATE_REQUEST";
export const ORGANIZATION_CREATE_SUCCESS = "ORGANIZATION_CREATE_SUCCESS";
export const ORGANIZATION_CREATE_FAIL = "ORGANIZATION_CREATE_FAIL";
export const ORGANIZATION_CREATE_RESET = "ORGANIZATION_CREATE_RESET";

export const ORGANIZATION_UPDATE_REQUEST = "ORGANIZATION_UPDATE_REQUEST";
export const ORGANIZATION_UPDATE_SUCCESS = "ORGANIZATION_UPDATE_SUCCESS";
export const ORGANIZATION_UPDATE_FAIL = "ORGANIZATION_UPDATE_FAIL";
export const ORGANIZATION_UPDATE_RESET = "ORGANIZATION_UPDATE_RESET";
