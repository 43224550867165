import {useState, useEffect } from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import {editBuild, updateBuildAccess } from '../../../redux/actions/BuildActions'; 
import {updateApp} from '../../../redux/actions/AppActions'; 
import { UserDropdown } from '../../../redux/actions/userActions';
import { BUILD_EDIT_RESET } from "../../../redux/constants/BuildConstants";
import { toast } from "react-toastify";
import {ToastObjects} from '../../../redux/actions/toastObject'; 
import { useHistory } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Circles } from 'react-loader-spinner'; 
import './builds.css'

const BuildDetails = ({match}) => {	
  const history = useHistory();
  const appId = match.params.id;
  const buildId = match.params.buildId;
  const _organizationId = match?.params?.organizationId;
  const dispatch = useDispatch();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [loading, setLoading] = useState(false); 
  const [userIds, setUserIds] = useState([]);
  const [accessEdit, setAccessEdit] = useState(false);
  const buildEdit = useSelector((state) => state.buildEdit);
  const users = useSelector((state) => state.userDropdown.users);
  const { build } = buildEdit;
   const userRole = useSelector((state) => state.userLogin.userInfo.data.role);
const organization = useSelector((state)=>state?.organizationDetails?.organization);
const role = organization?.user_role;
const organizationId = organization?.organization_id;
const [selectedClientIds, setSelectedClientIds] = useState([]);

  useEffect(() => {
      if (build?.id !== Number(buildId)) {
        dispatch(editBuild(appId, buildId)).then((response)=>{
			if(response === 'Build not found.'){
				navigateToBuildList()
			}
		});        
      } else{
		setSelectedUserIds(build?.build_access);
		setUserIds(build?.build_access)
	  }
  }, [build, dispatch, buildId]);
  
    const navigateToBuildList = () => {
		dispatch({ type: BUILD_EDIT_RESET });
		if(_organizationId){
			history.push(`/organization/${_organizationId}/app/${appId}/builds`);
		} else {
			history.push(`/app/${appId}/builds`);
		}
		
	};

	useEffect(() => {
		if(role === "Admin" ||  role === "Member"){
			dispatch(UserDropdown('build', appId, organizationId));
		}
  	}, [organizationId]);

	const handleUserChange = (event) => {
	  const selectedUserId = parseInt(event.target.value);
	  if (!selectedUserIds.includes(selectedUserId)) {
		setSelectedUserIds([...selectedUserIds, selectedUserId]);
	  }
	};

	// const handleClientChange = (event) => {
	// 	const selectedClientId = parseInt(event.target.value);
	// 	if (!selectedClientIds.includes(selectedClientId)) {
	// 	  setSelectedClientIds([...selectedClientIds, selectedClientId]);
	// 	}
	// };
  
	const removeUser = (userIdToRemove) => {
	  setSelectedUserIds(selectedUserIds.filter(userId => userId !== userIdToRemove));
	};

	const handleClientChange = (event) => {
		const selectedClientId = parseInt(event.target.value);
		if (!selectedClientIds.includes(selectedClientId)) {
		  setSelectedClientIds([...selectedClientIds, selectedClientId]);
		}
	};

	const removeClient = (clientIdToRemove) => {
		setSelectedClientIds(selectedClientIds.filter(clientId => clientId !== clientIdToRemove));
	};

	const filteredClientOptions = users.filter(option => !selectedClientIds.includes(option.id) && option.role === 'Client');
    const selectedClients = users.filter(option => selectedClientIds.includes(option.id) && option.role === 'Client');
	const clients = users.filter(option => option.role === 'Client');
  
	let filteredOptions = [];
	let selectedUsers = [];
	if(role === "Admin" ||  role === "Member"){
		filteredOptions = users.filter(option => !selectedUserIds.includes(option.id));
		selectedUsers = users.filter(option => selectedUserIds.includes(option.id));
	}

	
	// const getFileNameFromUrl = (url) => {
	// 	return url.substring(url.lastIndexOf('/') + 1);
	//   };

      const downloadBuild = () => {
        const link = document.createElement('a');
        link.href = build?.file_path;
        link.download = `app -${build?.version}`; 
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
	  };

	  const handleSubmit = (e) => {
        e.preventDefault();
		const removeIds = userIds.filter((element) => !selectedClientIds.includes(element));
        setLoading(true);
		let obj = {
			selectedUserIds: selectedClientIds,
			removeIds: removeIds,
            os: build?.os,
			version: build?.version
		}

		dispatch(updateBuildAccess(obj, appId, buildId)).then((res)=>{
			setLoading(false)
			navigateToBuildList();
		});
	  }

	  const ViewOnlyEditor = ({ data }) => {
		return (
			<div className="view-only-editor">
				<CKEditor
					editor={ClassicEditor}
					data={data}
					disabled={true}
					config={{
						// Disable editing and toolbars
						toolbar: [], // Hides toolbar
						isReadOnly: true, // Disables editing
					}}
					onReady={editor => {
						// Optionally handle when editor is ready
					}}
				/>
			</div>
		);
	};

	return(
		<>
		    <div className="container-scroller">
				<Header/>
				<div className="container-fluid page-body-wrapper">
				   <Sidebar/>
				   <div className="main-panel">
				        <div className="content-wrapper">
					        <div className="row">
				               <div className="col-12 grid-margin">
				                  <div className="card">
				                     <div className="card-body">
				                        <div className="card-title">
                                        <div className="form-group row">
                                            <h4 className="col-form-label">  
                                              <i className="fa fa-arrow-left" onClick={navigateToBuildList}></i> Build Details
                                            </h4>
                                        </div> 
                                        </div>
										<form className="form-sample" onSubmit={handleSubmit}>
				                           <p className="card-description">				                              
				                           </p>
				                           <div className="row">
				                              <div className="col-md-6">
				                                 <div className="form-group row">
													<h4 className="col-sm-3 col-form-label">Build OS :</h4>
											        <div className="col-sm-9">
											            <h4 className="col col-form-label">{build?.os}</h4>
											        </div>
				                                 </div>
				                              </div>				                              
				                           </div>
				                           <div className="row">
				                              <div className="col-md-6">
				                                 <div className="form-group row">
													<h4 className="col-sm-3 col-form-label">Version :</h4>
											        <div className="col-sm-9">
											            <h4 className="col col-form-label">{build?.version}</h4>
											        </div>
												 </div>
				                              </div>				                              
				                           </div>
				                           <div className="row">
				                                <div className="col-md-6">
											        <div className="form-group row">
											            <h4 className="col-sm-3 col-form-label">Notes :</h4>
											            <div className="col-sm-9">
											               {/* <h4 className="col col-form-label">{build.notes}</h4> */}
														   <ViewOnlyEditor data={build?.notes} />
														</div>
                                                    </div>
				                                </div>				                              
				                           </div>
                                           <div className="row">
				                                <div className="col-md-6">
											        <div className="form-group row">
											            <h4 className="col-sm-3 col-form-label">Build File : </h4>
											            <div className="col-sm-9">
													       <i className="fa fa-download download-csv pl-10" onClick={downloadBuild} title="Download Build"/>
											            </div>
                                                    </div>
				                                </div>				                              
				                           </div>

                                           {accessEdit === true && (role === "Admin" ||  role === "Member")  && userRole !== "Super_Admin" ?//
										        <>
												<div className="row">
										            <div className="col-md-6">
											            <div className="form-group row">
												            <h4 className="col-sm-3 col-form-label">Client Access :</h4>
												            <div className="col-sm-8"  style={{"paddingLeft":"20px"}}>
													            <div className="custom-select-container">
													                <div className="selected-roles">
														                {selectedUsers.map((user) => (
															                <span key={user.id} className="selected-role">
																               {user.name}
																               <button type="button" className="remove-role-btn" onClick={() => removeUser(user.id)}>x</button>
															               </span>
														                ))}
														                <select className="form-control role-select" onChange={handleUserChange} value="">
															                <option value="" disabled>Select User</option>
															                {filteredOptions.map((user) => (
																               <option key={user.id} value={user.id}>{user.name}</option>
															                ))}
														               </select>
													                </div>
													            </div>
												            </div>
															<div className="col-sm-1" >
															   <i className="fa fa-times" onClick={()=> setAccessEdit(false)} ></i>
															</div>
											            </div>
										            </div>
									            </div>
												<div className="text-center">
				                            	<button type="submit" className="btn btn-primary me-2">Submit</button>
                    						    </div>
												</>
										   : accessEdit === false && (role === "Admin" ||  role === "Member")  && userRole !== "Super_Admin" ?// 
										   <div className="row">
										   <div className="col-md-6">
											   <div className="form-group row">
												   <h4 className="col-sm-3 col-form-label">Client Access : </h4>
												   <div className="col-sm-9"  style={{"paddingLeft":"20px"}}>
												   {clients.length > 0 ? 
													 <div className="custom-select-container">
													 <div className="selected-roles">
														 {selectedClients.map((user) => (
															 <span key={user.id} className="selected-role">
																{user.name}
																<button type="button" className="remove-role-btn" onClick={() => removeClient(user.id)}>x</button>
															 </span>
														 ))}
														 <select className="form-control role-select" onChange={handleClientChange} value="">
															 <option value="" disabled>Select User</option>
															 {filteredClientOptions.map((user) => (
																 <option key={user.id} value={user.id}>{user.name}</option>
															 ))}
														 </select>
													 </div>
													 </div>
												   :
													 <label className="col-sm-12 col-form-label">Contact Admin, to add Client.</label>
												   }
											   </div>
											   </div>
										   </div>				                              
									   </div>
										    : null}
											{selectedClients.length > 0 && role !== 'Client'  && userRole !== "Super_Admin" ?
										    <div className="text-center">
										      <button type="submit" className="Button col-sm-2 col-form-label text-center mr-2">Submit</button>
										      <Link to={`/app/${appId}/builds`}><button className="ButtonLight col-sm-2 col-form-label text-center ml-2">Cancel</button></Link>
									        </div>
									    : null}
				                        </form>
										
				                     </div>
				                  </div>
				               </div>
				            </div>
			            </div>
				       <Footer/>
				    </div>
				</div>
			</div>
			{loading &&  <div className="fullscreen-loader">
          <Circles color="#1F3BB3" height={80} width={80} />
        </div>}
		</>
		)
}

export default BuildDetails;