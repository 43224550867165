import {
    ORGANIZATION_CREATE_FAIL,
    ORGANIZATION_CREATE_REQUEST,
    ORGANIZATION_CREATE_RESET,
    ORGANIZATION_CREATE_SUCCESS,
    ORGANIZATION_DELETE_FAIL,
    ORGANIZATION_DELETE_REQUEST,
    ORGANIZATION_DELETE_SUCCESS,
    ORGANIZATION_EDIT_FAIL,
    ORGANIZATION_EDIT_REQUEST,
    ORGANIZATION_EDIT_SUCCESS,
    ORGANIZATION_LIST_FAIL,
    ORGANIZATION_LIST_REQUEST,
    ORGANIZATION_LIST_SUCCESS,
    ORGANIZATION_LIST_RESET,
    ORGANIZATION_UPDATE_FAIL,
    ORGANIZATION_UPDATE_REQUEST,
    ORGANIZATION_UPDATE_RESET,
    ORGANIZATION_UPDATE_SUCCESS,
    ORGANIZATION_DETAILS_REQUEST,
    ORGANIZATION_DETAILS_SUCCESS,
    ORGANIZATION_DETAILS_FAIL,
    ORGANIZATION_DETAILS_RESET
  } from "../constants/OrganizationConstants";
  
  // All organizations
  export const organizationListReducer = (state = { organizations: [], numOfPages:0, sortBy: '', searchText:'', filterByOs: '', existingCount: 0, requestedCount: 0 }, action) => {
    switch (action.type) {
  
      case ORGANIZATION_LIST_REQUEST:
        return { loading: true, organizations: [], numOfPages:0 };
      case ORGANIZATION_LIST_SUCCESS:
        return { loading: false, organizations: action.payload.data, numOfPages: action.payload.numOfPages, sortBy: action.payload.sortBy, searchText: action.payload.searchText , filterByOs: action.payload.filterByOs, existingCount: action.payload.existingCount, requestedCount: action.payload.requestedCount };
      case ORGANIZATION_LIST_FAIL:
        return { loading: false, error: action.payload.data, numOfPages: action.payload.numOfPages, sortBy: action.payload.sortBy, searchText: action.payload.searchText,  filterByOs: action.payload.filterByOs };
      case ORGANIZATION_LIST_RESET:
        return { loading: true, organizations: [], numOfPages:0 };
      default:
        return state;
    }
  };
  
  // Details organization
  export const organizationDetailsReducer = (state = { organization: {} }, action) => {
    switch (action.type) {
      case ORGANIZATION_DETAILS_REQUEST:
        return { loading: true };
      case ORGANIZATION_DETAILS_SUCCESS:
        return { loading: false, success: true, organization: action.payload };
      case ORGANIZATION_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      case ORGANIZATION_DETAILS_RESET:
        return { organization: {} };
      default:
        return state;
    }
  };
  // Delete organization
  export const organizationDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case ORGANIZATION_DELETE_REQUEST:
        return { loading: true };
      case ORGANIZATION_DELETE_SUCCESS:
        return { loading: false, success: true };
      case ORGANIZATION_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // Create organization
  export const organizationCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case ORGANIZATION_CREATE_REQUEST:
        return { loading: true };
      case ORGANIZATION_CREATE_SUCCESS:
        return { loading: false, success: true, organization: action.payload };
      case ORGANIZATION_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case ORGANIZATION_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  // Edit organization
  export const organizationEditReducer = (
    state = { organization: {}, userCount: 0, appCount: 0 },
    action
  ) => {
    switch (action.type) {
      case ORGANIZATION_EDIT_REQUEST:
        return { ...state, loading: true };
      case ORGANIZATION_EDIT_SUCCESS:
        return { loading: false, organization: action.payload.data, appCount: action.payload.appCount, userCount: action.payload.userCount };
      case ORGANIZATION_EDIT_FAIL:
        return { loading: false, error: action.payload.data };
      default:
        return state;
    }
  };
  
  // Update organization
  export const organizationUpdateReducer = (state = { organization: {} }, action) => {
    switch (action.type) {
      case ORGANIZATION_UPDATE_REQUEST:
        return { loading: true };
      case ORGANIZATION_UPDATE_SUCCESS:
        return { loading: false, success: true, organization: action.payload };
      case ORGANIZATION_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case ORGANIZATION_UPDATE_RESET:
        return { organization: {} };
      default:
        return state;
    }
  };
  