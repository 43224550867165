import {Link} from 'react-router-dom';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import { useDispatch, useSelector } from "react-redux";
import { deleteApp } from "../../../redux/actions/AppActions";

const Apps = (props) => {
	let { id, name, logo, description, isAndriod, isIos, app_status, requestedBy } = props.app;
	let from = props.from;
	let _organizationId = props?.organizationId;
	const organization = useSelector((state)=>state.organizationDetails.organization)
    const organizationRoles = organization?.user_role;
	const organizationId = organization?.organization_id;

	const role = useSelector((state) => state.userLogin.userInfo.data.role);
	let os;

	if(isAndriod === '1' && isIos === '1'){
		os = 'Andriod, iOS'
	} else if(isAndriod === '1'){
	    os = 'Andriod'
	} else if(isIos === '1'){
	    os = 'iOS'
	}

	const dispatch = useDispatch();

	const deletehandler = (id) => {
	    if (window.confirm("Are you sure want to delete app?")) {
	      dispatch(deleteApp(id));
	    }
	};

	return(
		<>
		    <tr>
                <td>{name}</td>
                <td><img src={logo} /></td>
			    <td>{description}</td>
                <td>{os}</td>

			    {app_status === 'Requested' && (role === 'Super_Admin')  ?
			      <td>{requestedBy}</td>
			    :  from === "Apps" ?
			        <td>
					    <Link to={`/app/${id}/builds`}>
				          <i className="fa fa-cogs"></i>
			            </Link>
		            </td>
				 : null}
				
			
			    { role === 'Super_Admin' && app_status !== "Requested" ?
				    <>
					   	<td> 
					        <button className="badge-active">
						      {app_status}
					        </button>
					    </td> 
					    <td>
					        <Link to={`/organization/${_organizationId}/app/${id}/builds`}>
				              <i className="fa fa-cogs"></i>
			                </Link>
		                </td>
					</>
			    : null}

                {role !== 'Super_Admin' && organizationRoles === 'Admin' &&  app_status !== "Requested"?
				<>
				   <td> 
				       <button className="badge-active">
					    {app_status}
				        </button>
			        </td> 
                    <td>
						<Link  to={`/app/edit/${id}`} >
							<i class="bi bi-pencil-square editICon"></i>
			            </Link>
		            </td>
					</>
				: null}

				{role !== 'Super_Admin' && organizationRoles === 'Admin' &&  app_status === "Requested"?
				  <>
				    <td>{requestedBy}</td> 
				    <td>
				        <Link  to={`/app/edit/${id}`} >
					     <i class="bi bi-pencil-square editICon"></i>
				        </Link>
			        </td>
				  </>
				: null}
            </tr>
		</>
	)
}

export default Apps;