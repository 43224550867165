import { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import { useHistory } from 'react-router-dom';
import { Circles } from 'react-loader-spinner'; 
import '../main.css'
import { toast } from "react-toastify";
import { createOrganization, Organizationlist } from '../../../redux/actions/OrganizationAction';
import {
	ORGANIZATION_DETAILS_SUCCESS
} from "../../../redux/constants/OrganizationConstants";

const CreateOrganization = () => {
    const history = useHistory();
	const dispatch = useDispatch();
	const role = useSelector((state) => state.userLogin.userInfo.data.role);
    const [submitted, setSubmitted] = useState(false);  
	const [loading, setLoading] = useState(false); 
	const {userLogin: { userInfo :{data} }} = useSelector((state) => state);  
	const [formState,setFormState] = useState({
        values:{
			name:'',
            email:'',
            phone:0,
            address:''
		},
		errors: {}       
    });

    const handleChange = (event) => {
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value
            },
            errors: {
                ...formState.errors,
            }
        }));
    };
    

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true); 
        const { name, email, phone, address } = formState.values;

        if (name && email && phone && address) {
				let _errorFlag = false, _errorMessage = "";
				
				if(_errorFlag === false){
					console.log('Form is valid');

					setLoading(true)

					let payload = {
                        name:name,
                        email:email,
                        phone:phone,
                        address:address
                    }

					
					dispatch(createOrganization(payload)).then((res)=>{
						setSubmitted(false);
						setFormState(formState => ({
							...formState,
							errors: {
							  ...formState.errors,
							}
						}));
						setLoading(false);
						dispatch(Organizationlist('', '', '', '', "Member")).then((response)=>{
							if(response?.length > 0){
							  dispatch({ type: ORGANIZATION_DETAILS_SUCCESS, payload: response[0] });
							}
						  });
						navigateToAppList();
					});
				} else {
                   toast.error(_errorMessage);
				}
				
        } else if(!formState.values.name && !formState.values.email && !formState.values.phone && !formState.values.address){
			toast.error("Fill all Details")
		}
		else if(!formState.values.name) {
			toast.error("Enetr Name")
		}else if(!formState.values.email){
			toast.error("Enter Email")
		}else if(!formState.values.phone){
			toast.error("Enter Phone number")
		}else if(!formState.values.address){
            toast.error("Enter Address")
        }
    }

	const navigateToAppList = () => {
		history.push('/apps');
	};

	return(
		<>
		    <div className="container-scroller">
				<Header/>
				<div className="container-fluid page-body-wrapper">
				   <Sidebar/>
				   <div className="main-panel">
				        <div className="content-wrapper">
					        <div className="row">
				               <div className="col-12 grid-margin">
				                  <div className="card">
				                     <div className="card-body">
										<div className="header headingWrap">
										 <Link to="/apps" className ="backarrowcolor">
										 	<i class="bi bi-arrow-left-circle-fill backIcon"></i>
                                          </Link>
                                         <h4 className="card-title">{role !== 'Super_Admin'&&`Create Organization`}</h4>
                                         </div>	
				                        <form className="form-sample formStyle mt-4" onSubmit={handleSubmit}>
				                           <p className="card-description">				                              
				                           </p>
				                           <div className="row">
				                              <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Organization Name
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
									                        <input 
															type="text" 
															className={'form-control form-control-lg' + (submitted && !formState.values.name ? ' is-invalid' : '')} 
					                                        name="name"                                
					                                        onChange={handleChange}
					                                        value={formState.values.name || ''}
					                                        />
					                                        {submitted && !formState.values.name &&
					                                            <div className="inline-errormsg">Organization name is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>	
                                              <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Organization Email
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
									                        <input 
															type="text" 
															className={'form-control form-control-lg' + (submitted && !formState.values.email ? ' is-invalid' : '')} 
					                                        name="email"                                
					                                        onChange={handleChange}
					                                        value={formState.values.email || ''}
					                                        />
					                                        {submitted && !formState.values.email &&
					                                            <div className="inline-errormsg">Organization email is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>
                                              <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Organization Mobile Number
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
									                        <input 
															type="number" 
															className={'form-control form-control-lg' + (submitted && !formState.values.phone ? ' is-invalid' : '')} 
					                                        name="phone"                                
					                                        onChange={handleChange}
					                                        value={formState.values.phone || ''}
					                                        />
					                                        {submitted && !formState.values.phone &&
					                                            <div className="inline-errormsg">Organization mobile number is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>
                                              <div className="col-md-6">
				                                 <div className="form-group">
				                                    <label className="col-form-label">Organization Address
													<span style={{ color: 'red' }}>*</span>
													</label>
				                                    <div className="">
									                        <input 
															type="text" 
															className={'form-control form-control-lg' + (submitted && !formState.values.address ? ' is-invalid' : '')} 
					                                        name="address"                                
					                                        onChange={handleChange}
					                                        value={formState.values.address || ''}
					                                        />
					                                        {submitted && !formState.values.address &&
					                                            <div className="inline-errormsg">Organization address is required</div>
					                                        }
				                                    </div>
				                                 </div>
				                              </div>
					                              
				                           </div>
				                            <div className="text-left">
				                            	<button type="submit" className="Button col-sm-2 col-form-label text-center mr-2">Submit</button>
                    							{/* <Link to="/apps"><button className="ButtonLight col-sm-2 col-form-label text-center ml-2">Cancel</button></Link> */}
                    						</div>
				                        </form>
				                     </div>
				                  </div>
				               </div>
				            </div>
			            </div>
				       <Footer/>
				    </div>
				</div>
			</div>
			{loading &&  <div className="fullscreen-loader">
          <Circles color="#1F3BB3" height={80} width={80} />
        </div>}
		</>
		)
}

export default CreateOrganization;