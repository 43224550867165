import {useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from 'react-paginate';
import { Organizationlist } from '../../../redux/actions/OrganizationAction';
import Organization from './Organization';
import { UserDropdown } from '../../../redux/actions/userActions';
import './org.css'

const Organizations = () => {
	const dispatch = useDispatch();
  const users = useSelector((state) => state.userDropdown.users);
	const [currentPage, setCurrentPage] = useState(0);	
	const [user, setUser] = useState('')
	const [searchTerm, setSearchTerm] = useState('');
	const role = useSelector((state) => state.userLogin.userInfo.data.role);
	const organizationList = useSelector((state) => state.organizationList);
  const {  organizations, numOfPages, sortBy, searchText } = organizationList; 
	const [organizationsPerPage, setOrganizationsPerPage] = useState(20);

	let pageNum = 1;

  useEffect(() => {
    dispatch(Organizationlist(pageNum,organizationsPerPage, sortBy, searchTerm, role, 'user', user));
    dispatch(UserDropdown('organization',''));
  }, [organizationsPerPage]);

	const handleItemsPerPageChange = (e) => {
	  setOrganizationsPerPage(Number(e.target.value));
	  setCurrentPage(0);
	};

  const handlePageClick = (data) => {
  	pageNum = data.selected + 1;
  	setCurrentPage(data.selected);	
  	dispatch(Organizationlist(pageNum,organizationsPerPage, sortBy, searchTerm, role, 'user', user));
  }

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    const delaySearchFunc = setTimeout(() => {
      setCurrentPage(0);
      dispatch(Organizationlist(pageNum,organizationsPerPage, sortBy, searchTerm || '', role, 'user', user
      ));
    }, 1500);
    return () => clearTimeout(delaySearchFunc);
  };
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch(e.target.value);
    }
  };
  
  const handleIconClick = () => {
    if (searchTerm) {
      setSearchTerm('');
      handleSearch('');
    } else {
        const searchTerm = document.querySelector('input[name="search"]').value;
        handleSearch(searchTerm);
    }
  };

  	const handleFilterBy = (e) => {
  		const filterByUser = e.target.value;
		  setUser(filterByUser);
  		setCurrentPage(0);
  		dispatch(Organizationlist(pageNum,organizationsPerPage, sortBy, searchTerm, role, 'user', filterByUser));
  	}

  	// const getCsvApps = async () => {
  	// 	const responseData = await axios.get(`/organizations/all`);
    // 	const data = responseData.data;
    // 	setCsvData(data.data);
    // 	myRefBtn.current.link.click();
  	// }


	return(
		<>
    <div className="container-scroller">
      <Header/>
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                  <div className="header">
                    {/* <i className="fa fa-arrow-left" aria-hidden="true"></i> */}
                    <h4 className="card-title">Organizations</h4>
                    </div>
                    {/* Content based on active tab */}
                    
                      <>
                        {/* Filter and Add App Button */}
                        <div className="row mt-4">
						                  <div className="col-md-12">
	                                 <div className="form-group row">
	                                    <div className="col-sm-7 float-right">
	                                    	<select className="form-select" aria-label="Filter By User" onChange={handleFilterBy}>
											                    <option value="">Filter By User</option>
                                          <option value="">All</option>
                                          {users?.map((user)=>(
                                            <option key={user?.id} value={user?.id}>{user?.name}</option>
                                          ))}
											                  </select>
	                                    </div>
                                      <div className="col-sm-2"></div>
                                      <div className="col-sm-3">
                                        <div className="search-container">
                                          <input type="text" placeholder="Search" className="form-control" name="search" value={searchTerm}onChange={(e) => setSearchTerm(e.target.value)}onKeyDown={handleKeyDown} />
                                          <i className={`fa ${searchTerm ? 'fa-times' : 'fa-search'}`} aria-hidden="true" onClick={handleIconClick} style={{ cursor: 'pointer' }} ></i>
                                        </div>                                    
                                      </div>
	                                    {/* <div className="col-sm-2">
									                     	{ role === 'Super_Admin' ?
                                          <Link to="/app/add" className="ml-4">
                                            <button className="Button col-sm-8 col-form-label text-center">
                                              Add App
	                                          </button>
	                                        </Link>
											                  : role === 'Member' || 'Tester' ?
 											                    <Link to="/app/request" className="ml-4">
                                            <button className="Button col-sm-8 col-form-label text-center">
                                              New App Request
	                                          </button>
	                                        </Link>                    
											                  :null}								        
	                                    </div> */}
	                                    {/* <div className="col-sm-1">
	                                    	<i className="fa fa-download download-csv" onClick={getCsvApps} title="Download CSV"/>
	                                    	<CSVLink 
	                                    		data={csvData} 
	                                    		headers={headers}
      											className="d-none"
      											ref={myRefBtn}
      											filename={"App-Data.csv"}
	                                    		>											  
											</CSVLink>											
	                                    </div> */}
	                                 </div>
	                              </div>	
                        </div>

                        {/* Existing Apps Table */}
                        <div className="table-responsive">
                          <table className="table table-hover apps-table">
                            <thead>
                              <tr>
                                <th className="o-name">Name</th>
                                <th className="o-email">Email</th>
                                <th className="o-phone">Phone</th>
                                <th className="o-createdBy">Created By</th>
                                <th className="o-createdDate">Created Date</th>
                                <th className="o-status">Status</th>
                              </tr>
                            </thead>
                            {organizations.length > 0 ? (
                              <tbody>
                                {organizations.map((app) => (
                                  <Organization org={app} key={app.id} />
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan="6" className="text-center">No Organization Found</td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </div>
                      </>
                    

                    {/* Pagination and Items Per Page */}
                    <div className="d-flex justify-content-between align-items-center mt-4">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={numOfPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        forcePage={currentPage}
                      />
                      <div>
                        <select
                          className="form-select"
                          aria-label="Items Per Page"
                          onChange={handleItemsPerPageChange}
                        >
                          <option value="20">20 per page</option>
                          <option value="40">40 per page</option>
                          <option value="60">60 per page</option>
                          <option value="80">80 per page</option>
                          <option value="100">100 per page</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  
		</>
	)
}

export default Organizations;