import {Link} from 'react-router-dom';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const Organization = (props) => {
	let { id, name, email, phone, created_by, created_at, isActive } = props.org;
	const history = useHistory();

	const gotoDetails = () =>{
		history.push(`/organization/details/${id}`)
	}
	
	return(
		<>
		<tr className="cursor-pointer" onClick={()=> gotoDetails()}>
		  <td>{name}</td>
		  <td>{email}</td>
		  <td>{phone}</td>
		  <td>{created_by}</td>
		  <td>{ moment(created_at).format('MMM DD, YYYY, hh:mm A')}</td>
		  <td>
				{isActive ?
				 <button
				 className="badge-active"
			   >
				 Active 
			   </button>
			   : 
			   <button
			   className="badge-deactive"
			 >
			   Deactive
			 </button>
			    }
			 
			  </td>
		{/* <td>
		<Link
	                to={`/organization/details/${id}`}	 
					style={{ "paddingRight": "10px" }}  
					title="View Details"                 
	            >
	              	<i className="fa fa-eye"></i>
	            </Link>
		</td> */}
		</tr>
	</>
		)
}

export default Organization;