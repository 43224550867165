import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import { useDispatch, useSelector } from "react-redux";
import { listUser } from "../../../redux/actions/userActions";
import {useEffect, useState, useRef} from 'react';
import User from './User';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Circles } from 'react-loader-spinner'; 

const UsersDetails = (props) => {
    const { from, organizationId } = props;
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(0);
	const [role, setRole] = useState('');
    const [searchTerm, setSearchTerm] = useState('');	
	const userList = useSelector((state) => state.userList);
	const {  users, numOfPages } = userList; 
	const [userPerPage, setUserPerPage] = useState(20);
    const [loading, setLoading] = useState(false); 
	const userRole = useSelector((state) => state.userLogin?.userInfo?.data?.role);
    const organization = useSelector((state)=>state?.organizationDetails?.organization)
    const organizationIds = organizationId? organizationId : organization?.organization_id;

	const handleItemsPerPageChange = (e) => {
	  setUserPerPage(Number(e.target.value));
	  setCurrentPage(0);
	};

	let pageNum = 1;

	useEffect(() => {
        setLoading(true);
    	dispatch(listUser(pageNum, userPerPage,'role',role, searchTerm, userRole, from, organizationIds)).then((response)=>{
            setLoading(false);
        });
  	}, [userPerPage, organizationIds]);

	const handlePageClick = (data) => {
		pageNum = data.selected + 1;
		setCurrentPage(data.selected);	
        setLoading(true);
		dispatch(listUser(pageNum, userPerPage, 'role',role,searchTerm, userRole, from, organizationIds)).then((response)=>{
              setLoading(false);
        });
	}
    const handleSearch = (searchTerm) => {
        setSearchTerm(searchTerm);
        const delaySearchFunc = setTimeout(() => {
          setCurrentPage(0);
          dispatch(listUser(pageNum, userPerPage,'role',role, searchTerm || '', userRole, from, organizationIds));
        }, 1500);

        return () => clearTimeout(delaySearchFunc);
      };
      
      const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          handleSearch(e.target.value);
        }
      };
      
      const handleIconClick = () => {
        if(searchTerm){
            setSearchTerm('')
            handleSearch('')
        }else{
            const searchTerm = document.querySelector('input[name="search"]').value;
            handleSearch(searchTerm);    
        }
         };

	const handleFilterBy = (e) => {
		const filterByRole = e.target.value;
	    setRole(filterByRole)
		setCurrentPage(0);
        setLoading(true);
		dispatch(listUser(pageNum,userPerPage, 'role', filterByRole,searchTerm, userRole, from, organizationIds)).then((response)=>{
            setLoading(false);
        });
	}

	return(
		<>
		     <div className="card">
                                        <div className="card-body">
                                            {/* <h4 className="card-title">Users</h4> */}

                                            <div className="header">
                                         {/* <i className="fa fa-arrow-left" aria-hidden="true"></i> */}
                                         {from === 'users' ? 
                                              <h4 className="card-title">Users</h4>
                                          : null}
                                               </div>
				                          
                                            <div className="row mt-4">
						                  <div className="col-md-12">
	                                 <div className="form-group row">	                                 
	                                    
	                                    <div className="col-sm-7 float-right">
	                                    	<select className="form-select" aria-label="Filter By Role" onChange={handleFilterBy}>
                                           
                                                                <option value="">Filter By Role</option>
                                                                <option value="">All</option>
                                                                <option value="Member">Member</option>
                                                                <option value="Tester">Tester</option>
                                                                <option value="Client">Client</option>
                                                           
											</select>
	                                    </div>
                                      <div className="col-sm-3">
                                      
                                      <div className="search-container">
                                       <input type="text" placeholder="Search" className="form-control" name="search" value={searchTerm}onChange={(e) => setSearchTerm(e.target.value)}onKeyDown={handleKeyDown} />
                                       <i className={`fa ${searchTerm ? 'fa-times' : 'fa-search'}`} aria-hidden="true" onClick={handleIconClick} style={{ cursor: 'pointer' }} ></i>
                                           </div>                                    
	                                    </div>
	                                    <div className="col-sm-2"> 
                                            {from === 'organization' ? null :
                                                        <Link to="/user/add" className="ml-4">
                                                          <button className="Button col-sm-8 col-form-label text-center">
                                                                   Add User
                                                            </button>
                                                               </Link>
                                            }
   
                                                        </div>
	                                    {/* <div className="col-sm-1">
	                                    	<i className="fa fa-download download-csv" onClick={getCsvApps} title="Download CSV"/>
	                                    	<CSVLink 
	                                    		data={csvData} 
	                                    		headers={headers}
      											className="d-none"
      											ref={myRefBtn}
      											filename={"App-Data.csv"}
	                                    		>											  
											</CSVLink>											
	                                    </div> */}
	                                 </div>
	                              </div>	
                        </div>	

											<div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Role</th>
                                                            <th>Status</th>
                                                            {from === 'organization' ? null :
                                                                <>
                                                                 
                                                                  <th>Action</th>
                                                                </>
                                                            }
                                                        </tr>
                                                    </thead>
                                                    {users.length > 0 ?
                                                     <tbody>
                                                     {users.map((user) => (
                                                          <User user={user} from={from} key={user.id} />
                                                        ))}</tbody>: 
									                       <tbody>
                                                           <tr>
                                                      <td colSpan="6" className="text-center">No Users Found</td> 
                                                      </tr>
                                                  </tbody>
                                                   }
                                                </table>
                                            </div>

											{/* Pagination and Items Per Page */}
											<div className="d-flex justify-content-between align-items-center mt-4">
                                                <ReactPaginate						        
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    breakLabel={"..."}
                                                    pageCount={numOfPages}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                    forcePage={currentPage}
                                                />

                                                <div>
                                                    <select className="form-select" aria-label="Items Per Page" onChange={handleItemsPerPageChange}>
                                                    <option value="20">20 per page</option>
                                                    <option value="40">40 per page</option>
                                                    <option value="60">60 per page</option>
                                                    <option value="80">80 per page</option>
                                                    <option value="100">100 per page</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            {loading &&  
                <div className="fullscreen-loader">
                  <Circles color="#1F3BB3" height={80} width={80} />
                </div>
            }
		</>
	)
}

export default UsersDetails;