import {
    APP_CREATE_FAIL,
    APP_CREATE_REQUEST,
    APP_CREATE_RESET,
    APP_CREATE_SUCCESS,
    APP_DELETE_FAIL,
    APP_DELETE_REQUEST,
    APP_DELETE_SUCCESS,
    APP_EDIT_FAIL,
    APP_EDIT_REQUEST,
    APP_EDIT_SUCCESS,
    APP_LIST_FAIL,
    APP_LIST_REQUEST,
    APP_LIST_SUCCESS,
    APP_UPDATE_FAIL,
    APP_UPDATE_REQUEST,
    APP_UPDATE_RESET,
    APP_UPDATE_SUCCESS,
  } from "../constants/AppConstants";
  
  // All Apps
  export const appListReducer = (state = { apps: [], numOfPages:0, sortBy: '', searchText:'', filterByOs: '', existingCount: 0, requestedCount: 0 }, action) => {
    switch (action.type) {
  
      case APP_LIST_REQUEST:
        return { loading: true, apps: [], numOfPages:0 };
      case APP_LIST_SUCCESS:
        return { loading: false, apps: action.payload.data, numOfPages: action.payload.numOfPages, sortBy: action.payload.sortBy, searchText: action.payload.searchText , filterByOs: action.payload.filterByOs, existingCount: action.payload.existingCount, requestedCount: action.payload.requestedCount };
      case APP_LIST_FAIL:
        return { loading: false, error: action.payload.data, numOfPages: action.payload.numOfPages, sortBy: action.payload.sortBy, searchText: action.payload.searchText,  filterByOs: action.payload.filterByOs };
      default:
        return state;
    }
  };
  
  // Delete App
  export const appDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case APP_DELETE_REQUEST:
        return { loading: true };
      case APP_DELETE_SUCCESS:
        return { loading: false, success: true };
      case APP_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // Create App
  export const appCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case APP_CREATE_REQUEST:
        return { loading: true };
      case APP_CREATE_SUCCESS:
        return { loading: false, success: true, app: action.payload };
      case APP_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case APP_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  // Edit App
  export const appEditReducer = (
    state = { app: { reviews: [] } },
    action
  ) => {
    switch (action.type) {
      case APP_EDIT_REQUEST:
        return { ...state, loading: true };
      case APP_EDIT_SUCCESS:
        return { loading: false, app: action.payload.data };
      case APP_EDIT_FAIL:
        return { loading: false, error: action.payload.data };
      default:
        return state;
    }
  };
  
  // Update App
  export const appUpdateReducer = (state = { app: {} }, action) => {
    switch (action.type) {
      case APP_UPDATE_REQUEST:
        return { loading: true };
      case APP_UPDATE_SUCCESS:
        return { loading: false, success: true, app: action.payload };
      case APP_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case APP_UPDATE_RESET:
        return { app: {} };
      default:
        return state;
    }
  };
  