import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import AppListDetails from './AppListDetails';

const Applist = () => {
  
	return(
		<>
    <div className="container-scroller">
      <Header/>
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <AppListDetails from={"Apps"} organizationId ={null}/>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
		</>
	)
}

export default Applist;