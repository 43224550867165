/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Build from './Build';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import { useDispatch, useSelector } from "react-redux";
import { listBuilds } from "../../../redux/actions/BuildActions";
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import './builds.css';
import { Circles } from 'react-loader-spinner'; 

const Buildlist = ({match}) => {
    const appId = match.params.id;
	const _organizationId = match?.params?.organizationId;
	const history = useHistory();
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(0);	
	const [os, setOs] = useState('');
	const [loading, setLoading] = useState(false); 
	const buildList = useSelector((state) => state.buildList);
	const role = useSelector((state) => state.userLogin.userInfo.data.role);
  	const { builds, numOfPages, sortBy, searchText, app } = buildList; 
	const [buildsPerPage, setBuildsPerPage] = useState(20);
	const [searchTerm, setSearchTerm] = useState('');
	const organization = useSelector((state)=>state?.organizationDetails?.organization);
	const userRole = organization?.user_role;
	const organizationId = organization?.organization_id;

	let pageNum = 1;

	const handleItemsPerPageChange = (e) => {
		setBuildsPerPage(Number(e.target.value));
		setCurrentPage(0);
	};
  	

  	const handlePageClick = (data) => {
		setLoading(true)
  		pageNum = data.selected + 1;
  		setCurrentPage(data.selected);	
  		dispatch(listBuilds(appId, pageNum, buildsPerPage, sortBy,searchTerm, "", "", organizationId)).then(()=>{
          setLoading(false);
		});
  	}

	useEffect(() => {
		setLoading(true);
    	dispatch(listBuilds(appId, pageNum, buildsPerPage, sortBy, searchTerm, "", "", organizationId)).then(()=>{
			setLoading(false);
		});
  	}, [appId, buildsPerPage, organizationId, dispatch]);

	const handleFilterBy = (e) => {
		setLoading(true);
		const selectedOs = e.target.value;
	    setOs(selectedOs)
		setCurrentPage(0);
		dispatch(listBuilds(appId, pageNum, buildsPerPage, sortBy,searchTerm, "OS", selectedOs, organizationId )).then(()=>{
			setLoading(false);
		});
	}

	const handleSearch = (searchTerm) => {
		setSearchTerm(searchTerm);
		const delaySearchFunc = setTimeout(() => {
		  setCurrentPage(0);
		  dispatch(listBuilds(appId, pageNum, buildsPerPage, sortBy, searchTerm || '', "OS", os, organizationId));
		}, 1500);
		return () => clearTimeout(delaySearchFunc);
	  };
	  
	  const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
		  handleSearch(e.target.value);
		}
	  };
	  
	  const handleIconClick = () => {
		if(searchTerm){
			setSearchTerm('')
			handleSearch('')
		}else{
			const searchTerm = document.querySelector('input[name="search"]').value;
			handleSearch(searchTerm);
		}
	  };
	  const navigateToAppList = () => {
		if(match?.params?.organizationId){
			history.push(`/organization/details/${match?.params?.organizationId}`);
		} else {
			history.push(`/apps`);
		}
	};



	return(
		<>
		    <div className="container-scroller">		        
		        <Header/>	        
		        <div className="container-fluid page-body-wrapper">
		          <Sidebar/>
		          <div className="main-panel">
		            <div className="content-wrapper">
		              <div className="row">
		                <div className="col-lg-12 grid-margin stretch-card">
		                  <div className="card">
		                    <div className="card-body">
							  {/* <i className="fa fa-arrow-left" onClick={navigateToAppList}></i> {app?.name} */}
							  <div className="header">
                                          <i className="fa fa-arrow-left" aria-hidden="true" onClick={navigateToAppList}></i>
                                         <h4 className="card-title">{app?.name}</h4>
										 <img src={app?.logo_url} style={{"width": "35px", "height":"35px", paddingLeft:"10px"}}></img>
                                         </div>	
		                      <div className="row">
	                              <div className="col-md-12">
	                                 <div className="form-group row">	                                 
	                                    <div className=" col-sm-7">
										<h4 className="card-title" style={{ 'paddingTop': '10px'}}>
											Builds
											</h4>
						                        {/* <input type="text" placeholder="Search" className="form-control" 
		                                        name="search" onChange={(e) => setSearchTerm(e.target.value)}/> */}
	                                    </div>
	                                    {/* <div className="col-sm-3 float-right">
											{app?.isAndriod === '1' && app?.isIos === '1' ?
											    <select className="form-select form-control" aria-label="Sort By" value={os} onChange={handleFilterBy}>
											      <option value="">Filter By</option>
											      <option value="IOS">iOS</option>
											      <option value="Andriod">Android</option>
											      <option value="">All</option>
											    </select>
											
										    : null}
	                                    </div> */}

										<div className="col-sm-7 float-right">
										{app?.isAndriod === '1' && app?.isIos === '1' ?
											    <select className="form-select" aria-label="Sort By" value={os} onChange={handleFilterBy}>
											      <option value="">Filter By</option>
											      <option value="IOS">iOS</option>
											      <option value="Andriod">Android</option>
											      <option value="">All</option>
											    </select>
											
										    : null}
	                                    </div>
                                      <div className="col-sm-3">
                                      
                                      {/* <div className="search-container">
                                       <input type="text" placeholder="Search" className="form-control" name="search" value={searchTerm}onChange={(e) => setSearchTerm(e.target.value)}onKeyDown={handleKeyDown} />
                                       <i className={`fa ${searchTerm ? 'fa-times' : 'fa-search'}`} aria-hidden="true" onClick={handleIconClick} style={{ cursor: 'pointer' }} ></i>
                                           </div>                                     */}
	                                    </div>
	                                    <div className="col-sm-2">
											{ (userRole === "Admin" || userRole === "Member") && role !== 'Super_Admin' ?
											 	// <Link to={`/app/${appId}/build/add`} className="btn btn-outline-primary btn-fw float-right">
												//  New Build
											    // </Link>	
												<Link to={`/app/${appId}/build/add`} className="ml-4">
                                            <button className="Button col-sm-8 col-form-label text-center">
											New Build
	                                          </button>
	                                        </Link>
											: null}


	                                    								        
	                                    </div>
	                                    {/* <div className="col-sm-1">
	                                    	<i className="fa fa-download download-csv" onClick={getCsvApps} title="Download CSV"/>
	                                    	<CSVLink 
	                                    		data={csvData} 
	                                    		headers={headers}
      											className="d-none"
      											ref={myRefBtn}
      											filename={"App-Data.csv"}
	                                    		>											  
											</CSVLink>											
	                                    </div> */}
	                                 </div>
	                              </div>				                              
	                           </div>
		                      <div className="float-right mr-5">
			                      
		                      </div>		                      
		                      <p className="card-description">
		                      </p>
		                      <div className="table-responsive">
		                        <table className="table table-hover builds-table">
		                          <thead>
		                            <tr>
		                              {/* <th className="build-release">Release</th> */}
		                              <th className="build-version">Version</th>
		                              <th className="build-date">Date</th>
									  <th className="build-extension">OS</th>
		                              <th className="build-extension">Extension</th>
									  <th className="build-extension">Environment</th>
									  <th className="build-download">Action</th>
		                            </tr>
		                          </thead>
                                  {builds.length > 0 ?
                                    <tbody>
                                        {builds.map((build) => (
                                          <Build build={build} app={app} organizationId={_organizationId} key={build.id} />
                                        ))}	                            
                                    </tbody>
                                  : 
									<tbody>
                                <tr>
                                  <td colSpan="6" className="text-center">No Build Found</td>
                                </tr>
                              </tbody>
                                  }
		                        </table>
		                      </div>
							  <div className="d-flex justify-content-between align-items-center mt-4">
                                <ReactPaginate						        
                                   previousLabel={"Previous"}
                                   nextLabel={"Next"}
                                   breakLabel={"..."}
                                   pageCount={numOfPages}
                                   marginPagesDisplayed={2}
                                   pageRangeDisplayed={3}
                                   onPageChange={handlePageClick}
                                   containerClassName={"pagination"}
                                   pageClassName={"page-item"}
                                   pageLinkClassName={"page-link"}
                                   previousClassName={"page-item"}
                                   previousLinkClassName={"page-link"}
                                   nextClassName={"page-item"}
                                   nextLinkClassName={"page-link"}
                                   breakClassName={"page-item"}
                                   breakLinkClassName={"page-link"}
                                   activeClassName={"active"}
                                   forcePage={currentPage}
                                />
								<div>
                                  <select className="form-select" aria-label="Items Per Page" onChange={handleItemsPerPageChange}>
								  <option value="20">20 per page</option>
                          <option value="40">40 per page</option>
                          <option value="60">60 per page</option>
                          <option value="80">80 per page</option>
                          <option value="100">100 per page</option>
                                  </select>
                                </div>
                            </div>
		                    </div>
		                  </div>
		                </div>		                
		              </div>
		            </div>
		            <Footer/>
		          </div>
		        </div>		        
		      </div>
			  {loading &&  <div className="fullscreen-loader">
                  <Circles color="#1F3BB3" height={80} width={80} />
              </div>}
		</>
		)
}

export default Buildlist;