import {Link} from 'react-router-dom';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import { useDispatch } from "react-redux";
import { updateUser, listUser } from "../../../redux/actions/userActions";
import "./userDetails.css"

const User = (props) => {
	let { id, name, email, role, isActive } = props.user;
	let from = props.from;//role, 
	const dispatch = useDispatch();

	//   const deletehandler = (id) => {
	//     if (window.confirm("Are you sure want to delete app?")) {
	//     //   dispatch(deleteApp(id));
	//     }
	//   };

	const changeStatus = (id, status) =>{
    if(status === true){
		if (window.confirm(`Want to Activate ${name} ?`)) {
			let obj = {	id, isActive: status }
			dispatch(updateUser(obj)).then((response)=>{
				dispatch(listUser(1, 5));
			});
		}
	} else {
		if (window.confirm(`Want to Deactivate ${name} ?`)) {
			let obj = {	id, isActive: status }
			dispatch(updateUser(obj)).then((response)=>{
				dispatch(listUser(1, 5));
			});
		}
	}
 
	}

	return(
		<>
		    <tr>
              <td>{name}</td>
              <td>{email}</td>
			  <td>{role}</td>
			 
			  {from === 'organization'?  <td>
				{isActive ?
				 <button
				 className="badge-active"
			   >
				 Active 
			   </button>
			   : 
			   <button
			   className="badge-deactive"
			 >
			   Deactive
			 </button>
			    }
			 
			  </td> :
			  <>
			   <td>
				{isActive ?
				 <button
				 className="badge-active"
				 onClick={() => changeStatus(id, false)}
			   >
				 Active 
			   </button>
			   : 
			   <button
			   className="badge-deactive"
			   onClick={() => changeStatus(id, true)}
			 >
			   Deactive
			 </button>
			    }
			 
			  </td>
              <td>
				<Link
	                to={`/user/edit/${id}`}	                
	              >
	              	<i class="bi bi-pencil-square editICon"></i>
	              </Link>
	              {/* <Link
	                to="#"
	              //  onClick={() => deletehandler(id)}	                
	              >
	              	<i className="fa fa-trash"></i>
	              </Link> */}
	           </td>
			  </>
			  }
			 
            </tr>
		</>
		)
}

export default User;