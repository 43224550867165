import {useEffect, useState } from 'react';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
import { useDispatch, useSelector } from "react-redux";
import { getOrganization } from '../../../redux/actions/OrganizationAction';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppListDetails from '../apps/AppListDetails';
import UsersDetails from '../user/UsersDetails';
import { useHistory } from 'react-router-dom';
import OrganizationInfo from './OrganizationInfo';
import Badge from '@mui/material/Badge';

const OrganizationDetails = ({match}) => {
  const history = useHistory();
	const dispatch = useDispatch();
  const organizationId = match.params.id;
	const role = useSelector((state) => state.userLogin.userInfo.data.role);
	const [activeTab, setActiveTab] = useState('Apps');
  const organization = useSelector((state) => state?.organizationEdit?.organization); 	
  const appCount = useSelector((state) => state?.organizationEdit?.appCount);
  const userCount = useSelector((state) => state?.organizationEdit?.userCount);

	useEffect(() => {
    dispatch(getOrganization(organizationId))
  }, []);

	const handleChangeTab = (value) =>{
		setActiveTab(value);
	}
  
	return(
		<>
    <div className="container-scroller">
      <Header/>
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                  <div className="header">
                    <i className="fa fa-arrow-left" aria-hidden="true" onClick={()=> history.push('/organizations')}></i>
                    <h4 className="card-title">{organization?.name}</h4>
                    </div>   
					            {role === 'Super_Admin'?
                        <Box sx={{ width: '100%', bgcolor: '#fff7e2', margin: '20px 0 0 0' }}>
                        <Tabs 
                          value={activeTab}
                          onChange={(event, newValue) => handleChangeTab(newValue)}
                          TabIndicatorProps={{ style: { backgroundColor: '#1F3BB3' } }}
                        >
                          <Tab 
                            label={
                              <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <span className='app'>Apps</span>
                                <Badge badgeContent={appCount} className={`existingAppCount ${activeTab === 'Apps' ? 'active-badge' : ''}`}  sx={{ marginLeft: '15px' }} />
                              </span>
                            }
                            value="Apps"
                            className={activeTab === 'Apps' ? 'active-tab' : ''}
                          />
                          <Tab
                            label={
                              <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <span className='app'>Users</span>
                                <Badge badgeContent={userCount} className={`existingAppCount ${activeTab === 'Users' ? 'active-badge' : ''}`}
                                   sx={{ marginLeft: '15px' }} />
                              </span>
                            }
                            value="Users"
                            className={activeTab === 'Users' ? 'active-tab' : ''}
                          />
                            <Tab
                            label={
                              <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <span className='app'>About</span>
                                {/* <Badge badgeContent={userCount} className={`existingAppCount ${activeTab === 'Users' ? 'active-badge' : ''}`}
                                 sx={{ marginLeft: '15px' }} /> */}
                              </span>
                            }
                            value="About"
                            className={activeTab === 'About' ? 'active-tab' : ''}
                          />
                        </Tabs>
                      </Box>
                      
                      : null}

                    {/* Content based on active tab */}
                    {activeTab === 'Apps' ? (
                        <AppListDetails from={"organization"} organizationId ={organizationId}/>
                    ) : activeTab === 'Users' ?
                        <UsersDetails from={"organization"} organizationId ={organizationId} />
                      : 
                      <OrganizationInfo organizationId ={organizationId} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  
		</>
	)
}

export default OrganizationDetails;