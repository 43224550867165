import {Link,useHistory} from 'react-router-dom';
import { logout } from "../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import logo from "../images/bqt_logo.png";
import profilePic from "../images/faces/face8.jpg";
import { useEffect } from 'react';
import { Organizationlist } from '../redux/actions/OrganizationAction';
import { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  ORGANIZATION_DETAILS_SUCCESS
} from "../redux/constants/OrganizationConstants";

const Header = (props) => {
  const dispatch = useDispatch();  
  const organizations = useSelector((state)=> state.organizationList.organizations);
  const organization = useSelector((state)=> state.organizationDetails.organization);
  const [orgName, setOrgName] = useState("");
  const history = useHistory();

  useEffect(() => {
    const pageReloaded = sessionStorage.getItem('pageReloaded');
    if (pageReloaded) {
      console.log('Page was refreshed');
      dispatch(Organizationlist('', '', '', '', "Member")).then((response)=>{
        if(response?.length > 0){
          dispatch({ type: ORGANIZATION_DETAILS_SUCCESS, payload: response[0] });
          setOrgName(response[0].name)
        }
      });
    } else {
      console.log('First time loading the page');
      if(organizations?.length === 0){
        dispatch(Organizationlist('', '', '', '', "Member")).then((response)=>{
          if(response?.length > 0){
            dispatch({ type: ORGANIZATION_DETAILS_SUCCESS, payload: response[0] });
            setOrgName(response[0].name)
          }
        });
      }
      sessionStorage.setItem('pageReloaded', 'true');
    }

    // Add a beforeunload listener to detect when the user is leaving or refreshing the page
    const handleBeforeUnload = () => {
      sessionStorage.setItem('pageReloaded', 'true');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      sessionStorage.removeItem('pageReloaded');
    };
  }, []);

  const handleSetData = (value) =>{
    dispatch({ type: ORGANIZATION_DETAILS_SUCCESS, payload: value });
    if(value.organization_id){
     history.push("/apps");
    }
  }
 
  const logoutHandler = () => {
    dispatch(logout());  
  };

  const {userLogin: { userInfo :{data} }} = useSelector((state) => state);  

  let today = new Date();
  let curHr = today.getHours();
  let userMessage = '';
  
  if (curHr < 12) {
    userMessage = 'Good Morning';
  } else if (curHr < 17) {
    userMessage = 'Good Afternoon';
  } else {
    userMessage = 'Good Evening';
  }
  
	return(
		<nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
          <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
            <div className="me-3">
              <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-bs-toggle="minimize">
                <span className="icon-menu" />
              </button>
            </div>
            <div>
              <Link className="navbar-brand brand-logo" to="/apps">
                <img src={logo} alt="logo" />
              </Link>
            </div>
          </div>
          <div className="navbar-menu-wrapper d-flex align-items-top"> 
            <ul className="navbar-nav">
              <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
                <h1 className="welcome-text">{userMessage}, <span className="fw-bold">{data && data.name}</span></h1>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto">
            <>
            {data.role !== 'Super_Admin' && organizations?.length > 0 ? (
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="organization-select-label">Organization</InputLabel>
                  <Select
                    labelId="organization-select-label"
                    id="organization-select"
                    value={organization?.name || orgName}
                    label="Organization"
                  >
                    {organizations.map((org, index) => (
                      <MenuItem key={index} value={org?.name} onClick={() => {
                        handleSetData(org)
                        }}>
                        {org.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            ) : null}
          </>

              <li className="nav-item dropdown d-none d-lg-block user-dropdown">
                <a className="nav-link" id="UserDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                  <img className="img-xs rounded-circle" src={profilePic} alt="Profile image" /> </a>
                <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
                  <div className="dropdown-header text-center">
                    <img className="img-md rounded-circle" src={profilePic} alt="Profile image" />
                    <p className="mb-0 mt-3 font-weight-semibold userName">{data && data.name}</p>
                    <p className="fw-light text-muted mb-0">{data && data.email}</p>
                  </div>
                  <a className="dropdown-item" href='/user-password'><i className="dropdown-item-icon mdi mdi-account-outline me-2" /> Update Password </a>
                  {data.role !=='Super_Admin' &&<a className="dropdown-item" href='/organization/create'><i className="dropdown-item-icon mdi mdi-account-outline me-2" /> Create Organization </a>}
                  {/* <a className="dropdown-item"><i className="dropdown-item-icon mdi mdi-message-text-outline text-primary me-2" /> Messages <span className="badge badge-pill badge-danger">1</span></a>
                  <a className="dropdown-item"><i className="dropdown-item-icon mdi mdi-calendar-check-outline text-primary me-2" /> Activity</a>
                  <a className="dropdown-item"><i className="dropdown-item-icon mdi mdi-help-circle-outline text-primary me-2" /> FAQ</a> */}
                  <Link className="dropdown-item" to="#" onClick={logoutHandler}><i className="dropdown-item-icon mdi mdi-power  me-2"/>Log Out</Link>
                </div>
              </li>
            </ul>
            <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-bs-toggle="offcanvas">
              <span className="mdi mdi-menu" />
            </button>
          </div>
        </nav>
		)
}

export default Header;